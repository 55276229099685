import React from "react";
import Breadcrumb from "../../components/breadcrumb";
import Content from "../../components/content";
import CourseIndex from "../../components/courseIndex";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";
import { iBreadcrumbItem } from "../../interfaces/breadcrumb";

const breadcrumbItems: iBreadcrumbItem[] = [
    { path: '/', text: 'Inicio' },
    { path: '/academia', text: 'Academia' }
];

/** Página principal de los cursos */
export default function Course() {
    UsePageTitle('Academia');

    return (
        <>
            <Content>
                <Heading text="Academia" />
                <Breadcrumb items={breadcrumbItems} />
                <CourseIndex isFromManagePage={false} />
            </Content>
        </>
    );
}