import React from "react";
import { Link } from "react-router-dom";
import { iProductDetails } from "../interfaces/product";
import { formatCurrencyMXN } from "../utils";

interface iProductCardInfoProps {
    /** Detalles del producto. */
    product: iProductDetails;

    /** Indica si se esta accediendo al componente desde la página de administración de productos. */
    isFromManagePage?: boolean;
}

/** Pequeña card con información básica de un producto */
export default function ProductCardInfo({ product, isFromManagePage = false }: iProductCardInfoProps) {
    const img = product.media && product.media.length > 0 ? `/storage/products/${product.media[0]}` : "/img/no-image.jpg";

    return (
        <article className="uk-grid-small uk-flex-top" data-uk-grid>
            <div className="uk-width-1-2@m">
                <div className="uk-cover-container uk-height-medium">
                    <Link to={`/producto/${product.id}`} state={{ isFromManagePage }}>
                        <img src={img} alt="Imagen del producto" data-uk-cover />
                    </Link>
                </div>
            </div>
            <div className="uk-width-1-2@m">
                <div>
                    <h2 className="uk-h3 uk-margin-small-bottom">
                        <Link to={`/producto/${product.id}`} className="uk-link-heading" state={{ isFromManagePage }}>
                            {product.name}
                        </Link>
                    </h2>
                    <div>
                        {product.category}
                    </div>
                    <div>
                        <Link to={`/producto/${product.id}`} className="uk-link-heading" state={{ isFromManagePage }}>
                            <strong>{formatCurrencyMXN(product.price ?? 0)}</strong>
                        </Link>
                    </div>
                    <hr />
                    <ul className="uk-list">
                        <li>
                            <strong>Tipo: </strong>
                            <span>
                                {product.type}
                            </span>
                        </li>
                        <li>
                            <strong>Nivel: </strong>
                            <span>
                                {product.level}
                            </span>
                        </li>
                    </ul>
                    <p>
                        {product.summary}
                    </p>
                </div>
            </div>
        </article>
    );
}