import Cleave from "cleave.js/react";
import React, { ChangeEvent } from "react";
import { iAddressBase } from "../interfaces/address";

/** Interfaz con las propiedades para el formulario base de una dirección */
interface iAddressBaseFormProps {
    /** Modelo con los datos base de una dirección con el que trabajará el formulario */
    model: iAddressBase;

    /** Modelo que maneja los errores */
    errors: { [key: string]: string[] };

    /** Método que actualiza el modelo de la dirección */
    onAddressChange: (model: iAddressBase) => void;

    /** Indica si los inputs serán requeridos */
    requiredInputs?: boolean;
}

/**
 * Componente que muestra los inputs base para una dirección
 * 
 * @returns
 */
export default function AddressBaseForm({ model, errors, onAddressChange, requiredInputs = true }: iAddressBaseFormProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const updatedAddress = {
            ...model,
            [name]: value
        };

        onAddressChange(updatedAddress);
    };

    const handleZipCodeChange = (value: string) => {
        const updatedAddress: iAddressBase = {
            ...model,
            zipCode: value
        };

        onAddressChange(updatedAddress);
    }

    return (
        <>
            <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="address">Dirección:</label>
                <div className="uk-form-controls">
                    map component
                </div>
            </div>
            <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="street">Calle y número:</label>
                <div className="uk-form-controls">
                    <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-1-1 uk-width-1-2@s">
                            <input
                                type="text"
                                id="street"
                                name="street"
                                max="255"
                                value={model.street}
                                autoComplete="shipping address-level1"
                                className="uk-input"
                                onChange={handleChange}
                                required={requiredInputs} />

                            {errors?.Street && errors.Street.map((error, index) => (
                                <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                            ))}
                        </div>
                        <div className="uk-width-1-1 uk-width-1-2@s">
                            <input
                                type="text"
                                id="streetNumber"
                                name="streetNumber"
                                max="20"
                                value={model.streetNumber}
                                autoComplete="shipping address-level2"
                                className="uk-input"
                                onChange={handleChange}
                                placeholder="Número Interior"
                                required={requiredInputs} />

                            {errors?.StreetNumber && errors.StreetNumber.map((error, index) => (
                                <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="neighborhood">Colonia:</label>
                <div className="uk-form-controls">
                    <input
                        type="text"
                        id="neighborhood"
                        name="neighborhood"
                        value={model.neighborhood}
                        max="255"
                        autoComplete="shipping address-level3"
                        className="uk-input"
                        onChange={handleChange}
                        required={requiredInputs} />

                    {errors?.Neighborhood && errors.Neighborhood.map((error, index) => (
                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                    ))}
                </div>
            </div>
            <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="zipCode">Código Postal:</label>
                <div className="uk-form-controls">
                    <Cleave
                        id="zipCode"
                        name="zipCode"
                        value={model.zipCode}
                        required={requiredInputs}
                        autoComplete="shipping postal-code"
                        className="uk-input"
                        options={{
                            blocks: [5],
                            numericOnly: true,
                            numeralPositiveOnly: true
                        }}
                        onChange={(e: ChangeEvent) => handleZipCodeChange((e.target as any).rawValue)}
                    />

                    {errors?.ZipCode && errors.ZipCode.map((error, index) => (
                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                    ))}
                </div>
            </div>
            <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="city">Ciudad:</label>
                <div className="uk-form-controls">
                    <input
                        type="text"
                        id="city"
                        name="city"
                        max="255"
                        value={model.city}
                        autoComplete="shipping address-level2"
                        className="uk-input"
                        onChange={handleChange}
                        required={requiredInputs} />

                    {errors?.City && errors.City.map((error, index) => (
                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                    ))}
                </div>
            </div>
            <div className="uk-margin-small">
                <label className="uk-form-label" htmlFor="state">Estado:</label>
                <div className="uk-form-controls">
                    <input
                        type="text"
                        id="state"
                        name="state"
                        max="255"
                        value={model.state}
                        autoComplete="shipping address-level1"
                        className="uk-input"
                        onChange={handleChange}
                        required={requiredInputs} />

                    {errors?.State && errors.State.map((error, index) => (
                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                    ))}
                </div>
            </div>
        </>
    );
}