import { mdiAccountCircleOutline, mdiCartOutline, mdiMenu, mdiSchoolOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import UIkit from "uikit";
import Heading from "../components/heading";
import { ADMIN, CLASSCAPTURIST } from "../constants";
import { useUser } from "../userContext";

/** Header de la página que contiene la barra de navegación */
export default function Header() {
    const { user, logOut } = useUser();
    const location = useLocation();

    /** Cierra la seseón del usuario */
    const handleLogOut = () => {
        handleHiddenDropdown('account-dropdown')
        logOut();
    }

    /** Oculta el menú. */
    const handleHiddenCanvas = () => {
        UIkit.offcanvas('#offcanvas-nav').hide();
    }

    /**
     * Oculta el dropdown con el id dado.
     * @param id id del dropdown.
     */
    const handleHiddenDropdown = (id: string) => {
        UIkit.dropdown(`#${id}`).hide(false);
    }

    /** Muestra las opcines de las clases/cursos. */
    const renderCourseDropdownOptions = () => {
        const options = <>
            <li>
                <Link to="/academia"
                    onClick={() => handleHiddenDropdown('course-dropdown')}>
                    Ver Cursos
                </Link>
            </li>
            <li>
                <Link to={user != null ? '/suscripciones' : `/identificacion?returnUrl=${location.pathname}`}
                    reloadDocument={user == null}
                    onClick={() => handleHiddenDropdown('course-dropdown')}>
                    Mis Suscripciones
                </Link>
            </li>
        </>

        return options;
    }

    /** Muestra las opcines de la cuenta. */
    const renderAccountDropdownOptions = () => {
        let options = <>
            <li>
                <Link to="/registro-de-usuarios"
                    onClick={() => handleHiddenDropdown('account-dropdown')}>
                    Registrate
                </Link>
            </li>
            <li>
                <a href="/identificacion" rel="nofollow"
                    onClick={() => handleHiddenDropdown('account-dropdown')}>
                    Iniciar Sesión
                </a>
            </li>
        </>;

        const isClassCapturist = user && user.roles.includes(CLASSCAPTURIST);

        if (user != null) {
            options = <>
                <li>
                    <Link to="/dashboard"
                        onClick={() => handleHiddenDropdown('account-dropdown')}>
                        Mi Panel Principal
                    </Link>
                </li>
                {user.roles.includes(ADMIN) && <>
                    <li>
                        <Link to="/logs"
                            onClick={() => handleHiddenDropdown('account-dropdown')}>
                            Registro de Movimientos
                        </Link>
                    </li>
                </>}
                {isClassCapturist && <>
                    <li className="uk-nav-divider"></li>
                    <li>
                        <Link to="/administrar-cursos"
                            onClick={() => handleHiddenDropdown('course-dropdown')}>
                            Administrar Cursos
                        </Link>
                    </li>
                    <li>
                        <Link to="/administrar-suscripciones"
                            onClick={() => handleHiddenDropdown('course-dropdown')}>
                            Administrar Suscripciones
                        </Link>
                    </li>
                </>}
                <li className="uk-nav-divider"></li>
                <li>
                    <Link to="/cuenta/actualizacion-de-datos"
                        onClick={() => handleHiddenDropdown('account-dropdown')}>
                        Actualizar mis Datos
                    </Link>
                </li>
                <li>
                    <Link to="/cuenta/actualizacion-de-contrasena"
                        onClick={() => handleHiddenDropdown('account-dropdown')}>
                        Cambiar mi Contraseña
                    </Link>
                </li>
                {/*<li className="uk-nav-divider"></li>
                <li>
                    <Link to="/cuenta/informacion-de-envio"
                        onClick={() => handleHiddenDropdown('account-dropdown')}>
                        Dirección de Envío
                    </Link>
                </li>
                <li>
                    <Link to="/cuenta/informacion-de-pago"
                        onClick={() => handleHiddenDropdown('account-dropdown')}>
                        Información de Pago
                    </Link>
                </li>*/}
                <li className="uk-nav-divider"></li>
                <li>
                    <a onClick={handleLogOut}>
                        Salir
                    </a>
                </li>
            </>;
        }

        return options;
    }

    return (
        <header uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
            <nav className="uk-navbar-container">
                <div className="uk-container uk-container-expand uk-background-secondary">
                    <div data-uk-navbar>

                        <div className="uk-navbar-left">
                            <Link to="/" className="uk-navbar-item">
                                <img src="img/logo.png" alt="Logo de Lynx" style={{ minWidth: '80px', height: '32px' }} className="uk-logo" />
                            </Link>
                        </div>

                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav">
                                <li>
                                    <button className="uk-button uk-button-link">
                                        <span>
                                            <Icon path={mdiCartOutline} size={1} />
                                        </span>
                                        TIENDA
                                    </button>
                                    <div id="shop-dropdown" uk-dropdown="mode: click; pos: bottom-right">
                                        <ul className="uk-nav uk-navbar-dropdown-nav">
                                            {/*<li>*/}
                                            {/*    <Link to={user != null ? '/mis-pedidos' : `/identificacion?returnUrl=${location.pathname}`}*/}
                                            {/*        reloadDocument={user == null}*/}
                                            {/*        onClick={() => handleHiddenDropdown('shop-dropdown')}>*/}
                                            {/*        Mis Pedidos*/}
                                            {/*    </Link>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*    <Link to="/carrito"*/}
                                            {/*        onClick={() => handleHiddenDropdown('shop-dropdown')}>*/}
                                            {/*        Ver Carrito de Compra*/}
                                            {/*    </Link>*/}
                                            {/*</li>*/}
                                            <li>
                                                <Link to="/productos"
                                                    onClick={() => handleHiddenDropdown('shop-dropdown')}>
                                                    Ver Productos
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <button className="uk-button uk-button-link">
                                        <span>
                                            <Icon path={mdiSchoolOutline} size={1} />
                                        </span>
                                        ACADEMIA
                                    </button>
                                    <div id="course-dropdown" uk-dropdown="mode: click; pos: bottom-right">
                                        <ul className="uk-nav uk-navbar-dropdown-nav">
                                            {renderCourseDropdownOptions()}
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <button className="uk-button uk-button-link">
                                        <span>
                                            <Icon path={mdiAccountCircleOutline} size={1} />
                                        </span>
                                        CUENTA
                                    </button>
                                    <div id="account-dropdown" uk-dropdown="mode: click; pos: bottom-right">
                                        <ul className="uk-nav uk-dropdown-nav">
                                            {renderAccountDropdownOptions()}
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <button className="uk-button uk-button-link" type="button" uk-toggle="target: #offcanvas-nav">
                                        <span>
                                            <Icon path={mdiMenu} size={1} />
                                        </span>
                                        MENÚ
                                    </button>

                                    <div id="offcanvas-nav" uk-offcanvas="flip: true; overlay: true">
                                        <div className="uk-offcanvas-bar">
                                            <Heading text="Menú" />
                                            <ul className="uk-nav uk-nav-default">
                                                <li>
                                                    <Link to="/" onClick={handleHiddenCanvas}>
                                                        Inicio
                                                    </Link>
                                                </li>
                                                {/*<li>*/}
                                                {/*    <Link to="/acerca-de" onClick={handleHiddenCanvas}>*/}
                                                {/*        Acerca de*/}
                                                {/*    </Link>*/}
                                                {/*</li>*/}
                                                <li className="uk-parent">
                                                    <Link to="/productos" onClick={handleHiddenCanvas}>
                                                        Productos
                                                    </Link>
                                                    {/*<ul className="uk-nav-sub">*/}
                                                    {/*    <li>*/}
                                                    {/*        <Link to="/productos" onClick={handleHiddenCanvas}>*/}
                                                    {/*            Categoria 1*/}
                                                    {/*        </Link>*/}
                                                    {/*    </li>*/}
                                                    {/*    <li>*/}
                                                    {/*        <Link to="/productos" onClick={handleHiddenCanvas}>*/}
                                                    {/*            Categoria 2*/}
                                                    {/*        </Link>*/}
                                                    {/*    </li>*/}
                                                    {/*    <li>*/}
                                                    {/*        <Link to="/productos" onClick={handleHiddenCanvas}>*/}
                                                    {/*            Categoria 3*/}
                                                    {/*        </Link>*/}
                                                    {/*    </li>*/}
                                                    {/*</ul>*/}
                                                </li>
                                                <li className="uk-parent">
                                                    <Link to="/academia " onClick={handleHiddenCanvas}>
                                                        Cursos
                                                    </Link>
                                                    {/*<ul className="uk-nav-sub">*/}
                                                    {/*    <li>*/}
                                                    {/*        <Link to="/academia" onClick={handleHiddenCanvas}>*/}
                                                    {/*            Categoria 1*/}
                                                    {/*        </Link>*/}
                                                    {/*    </li>*/}
                                                    {/*    <li>*/}
                                                    {/*        <Link to="/academia" onClick={handleHiddenCanvas}>*/}
                                                    {/*            Categoria 2*/}
                                                    {/*        </Link>*/}
                                                    {/*    </li>*/}
                                                    {/*    <li>*/}
                                                    {/*        <Link to="/academia" onClick={handleHiddenCanvas}>*/}
                                                    {/*            Categoria 3*/}
                                                    {/*        </Link>*/}
                                                    {/*    </li>*/}
                                                    {/*</ul>*/}
                                                </li>
                                                <li>
                                                    <Link to="/contacto" onClick={handleHiddenCanvas}>
                                                        Contacto
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </nav>
        </header>
    );
}
