import React from "react";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";

/** Página que muestra la confirmación de la renovación a una suscripción */
export default function SubscriptionRenewalConfirmation() {
    UsePageTitle('Confirmación de renovación');

    return (
        <Content>
            <Heading text="¡Gracias por tu pago!" />
            <section>
                <p>Contenido</p>
            </section>
        </Content>
    );
}