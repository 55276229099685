import { mdiPrinter } from "@mdi/js";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Content from "../../components/content";
import Heading from "../../components/heading";
import SelectedCourseInfo from "../../components/selectedCourseInfo";
import UsePageTitle from "../../components/usePageTitle";
import { iCourseDetails } from "../../interfaces/course";
import { iParticipantDetails } from "../../interfaces/participant";
import { iResponsibleDetails } from "../../interfaces/responsible";
import { iSubscriptionDetails } from "../../interfaces/subscription";
import { formatDate, getCurrentDateTime } from "../../utils";

// TODO: Pendiente cambiar el correo electrónico y el teléfono por datos reales.

/** Página para confirmar el registro a un curso */
export default function CourseRegisterConfirmation() {
    const { id } = useParams<{ id: string }>();

    const { state } = useLocation() as {
        state: { subscription: iSubscriptionDetails, course: iCourseDetails } | null;
    };

    const [course, setCourse] = useState<iCourseDetails | null>(state?.course || null);
    const [subscription, setSubscription] = useState<iSubscriptionDetails | null>(state?.subscription || null);
    const [responsible, setResponsible] = useState<iResponsibleDetails | null>(null);

    const [responsibleId, setResponsibleId] = useState<number | null>(null);

    useEffect(() => {
        /** Realiza el fetch solo si no se mando la información de la suscripción desde la página anterior */
        if (!state?.subscription) {
            fetch(`/api/Me/Subscription/Details/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    const subscriptionData = data as iSubscriptionDetails;

                    setSubscription(subscriptionData);

                    setResponsibleId(subscriptionData.participants[0].responsibleId);
                });
        } else {
            setResponsibleId(state.subscription.participants[0].responsibleId);
        }
    }, [id, state?.subscription]);

    useEffect(() => {
        if (subscription) {
            /** Realiza el fetch solo si no se mando la información del curso desde la página anterior */
            if (!state?.course) {
                fetch(`/api/Course/Details/${subscription.courseId}`)
                    .then(res => {
                        if (!res.ok) {
                            window.location.href = `${window.location.origin}/Error`;
                        }

                        return res.json()
                    })
                    .then(data => {
                        const course = data as iCourseDetails;

                        // Cambia el precio del curso para evitar que se muestre un precio distinto por el que se pago en la suscripción en caso de que editen el curso.
                        course.price = subscription.acquiredClasses
                            ? subscription.price / subscription.acquiredClasses
                            : subscription.price;

                        setCourse(course);
                    });
            }

            // NOTA: A diferencia de la página courses/payment aquí no se cambia el precio del curso en el caso de que se manden los datos de este desde la página anterior debido a que en la página anterior ya se hizo el cambio de precio.
        }

    }, [state?.course, subscription]);

    useEffect(() => {
        if (responsibleId) {
            fetch(`/api/Me/Responsible/Details/${responsibleId}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    setResponsible(data as iResponsibleDetails);
                });
        }
    }, [responsibleId]);

    /** Muestra la página de la carta responsiva del participante y la opción de imprimir */
    const handlePrint = (participant: iParticipantDetails) => {
        const printWindow = window.open(`/carta-responsiva?participantName=${participant.name}&parentName=${participant.parentName}&birthdate=${participant.birthdate}`, '_blank');

        if (printWindow) {
            /** Muestra la opción de imprimir una vez haya cargado el documento */
            printWindow.onload = function () {
                printWindow.print();

                /** Cierra la ventana abierta despues de que el usuario haya guardado la carta responsiva o haya cancelado la impresión */
                printWindow.onafterprint = function () {
                    printWindow.close();
                };
            };
        }
    }

    UsePageTitle('Confirmación de registro');

    return (
        <Content>
            <Heading text="¡Gracias por tu compra!" />

            <div className="uk-child-width-1-1 uk-child-width-1-3@m" data-uk-grid>
                <div className="uk-width-2-3@m">
                    <p>
                        Tu solicitud ha sido recibida y está siendo procesada. A continuación, encontrarás los detalles de tu registro:
                    </p>
                    <section>
                        <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                            <span className="uk-width-1-3@s uk-width-1-4@m">
                                Número de registro:
                            </span>
                            <span>
                                <strong>
                                    #{subscription?.id}
                                </strong>
                            </span>
                        </div>
                        <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                            <span className="uk-width-1-3@s uk-width-1-4@m">
                                Fecha:
                            </span>
                            <span>
                                <strong>
                                    {formatDate(getCurrentDateTime(), true)}
                                </strong>
                            </span>
                        </div>
                    </section>
                    <section>
                        <h2 className="uk-h3 uk-margin-medium-top">
                            Responsable
                        </h2>
                        <section>

                            <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                <span className="uk-width-1-3@s uk-width-1-4@m">
                                    Nombre:
                                </span>
                                <span>
                                    <strong>
                                        {responsible?.name}
                                    </strong>
                                </span>
                            </div>
                            <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                <span className="uk-width-1-3@s uk-width-1-4@m">
                                    Teléfono:
                                </span>
                                <span>
                                    <strong>
                                        {responsible?.phone}
                                    </strong>
                                </span>
                            </div>
                            <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                <span className="uk-width-1-3@s uk-width-1-4@m">
                                    E-mail:
                                </span>
                                <span>
                                    <strong>
                                        {responsible?.email}
                                    </strong>
                                </span>
                            </div>
                            <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                <span className="uk-width-1-3@s uk-width-1-4@m">
                                    Calle y número:
                                </span>
                                <span>
                                    <strong>{responsible?.street} {responsible?.streetNumber}</strong>
                                </span>
                            </div>
                            <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                <span className="uk-width-1-3@s uk-width-1-4@m">
                                    Colonia:
                                </span>
                                <span>
                                    <strong>
                                        {responsible?.neighborhood}
                                    </strong>
                                </span>
                            </div>
                            <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                <span className="uk-width-1-3@s uk-width-1-4@m">
                                    Código Postal:
                                </span>
                                <span>
                                    <strong>
                                        {responsible?.zipCode}
                                    </strong>
                                </span>
                            </div>
                            <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                <span className="uk-width-1-3@s uk-width-1-4@m">
                                    Ciudad:
                                </span>
                                <span>
                                    <strong>
                                        {responsible?.city}
                                    </strong>
                                </span>
                            </div>
                            <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                <span className="uk-width-1-3@s uk-width-1-4@m">
                                    Estado:
                                </span>
                                <span>
                                    <strong>
                                        {responsible?.state}
                                    </strong>
                                </span>
                            </div>
                            {/*<div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">*/}
                            {/*    <span className="uk-width-1-3@s uk-width-1-4@m">*/}
                            {/*        Credencial de Elector:*/}
                            {/*    </span>*/}
                            {/*    <span>*/}
                            {/*        {responsible?.credentialFront}  {responsible?.credentialBack}*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                        </section>
                    </section>
                    <section>
                        {subscription && subscription.participants.map((participant, index) => (
                            <Fragment key={participant.id}>
                                <h2 className="uk-h3 uk-margin-medium-top">
                                    Alumno {index + 1}
                                </h2>
                                <section>
                                    <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                        <span className="uk-width-1-3@s uk-width-1-4@m">
                                            Nombre:
                                        </span>
                                        <span>
                                            <strong>
                                                {participant.name}
                                            </strong>
                                        </span>
                                    </div>
                                    <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                        <span className="uk-width-1-3@s uk-width-1-4@m">
                                            Fecha de nacimiento:
                                        </span>
                                        <span>
                                            <strong>
                                                {formatDate(participant.birthdate)}
                                            </strong>
                                        </span>
                                    </div>
                                    {participant.relationship && <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                        <span className="uk-width-1-3@s uk-width-1-4@m">
                                            Parentesco:
                                        </span>
                                        <span>
                                            <strong>
                                                {participant.relationship}
                                            </strong>
                                        </span>
                                    </div>}
                                    {participant.parentName && <div className="uk-flex uk-flex-column uk-flex-row@m uk-margin-small">
                                        <span className="uk-width-1-3@s uk-width-1-4@m">
                                            Nombre del Padre, Madre o Tutor:
                                        </span>
                                        <span>
                                            <strong>
                                                {participant.parentName}
                                            </strong>
                                        </span>
                                    </div>}
                                </section>

                                <button
                                    onClick={() => handlePrint(participant)}
                                    className="uk-button uk-button-primary uk-margin-small-top">
                                    Imprimir carta responsiva <Icon path={mdiPrinter} size={1} />
                                </button>
                            </Fragment>
                        ))}
                    </section>
                </div>
                <div>
                    {course != null && <SelectedCourseInfo course={course} btnMySubscription={true} />}
                </div>
            </div>
            <p>
                Recibirás una confirmación por correo electrónico en breve con los detalles de tu registro.
            </p>
            <p>
                Si tienes alguna pregunta o necesitas asistencia, no dudes en ponerte en contacto con nuestro equipo de soporte en <a href="mailto:ventas@lynxskatehouse.com">ventas@lynxskatehouse.com</a> o al <a href="tel:(012) 345 6789">(012) 345 6789</a>.
            </p>
            <Link to="/dashboard" className="uk-button uk-button-link">
                Ir a mi página principal
            </Link>
        </Content>
    );
}