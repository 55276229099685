import React from "react";
import { Constants } from "../constants";
import { SubscriptionStatusDictionary } from "../dictionaries";

/** 
 * Componente que muestra un label con el estatus de una suscripción en el color correspondiente.
 * 
 * Verde para Activa.
 * Negro para Finalizada.
 * Naranja para Pendiente de pago.
 * Rojo para Cancelada.
 * 
 * @param status Estatus de la suscripción.
 * @returns
 */
export default function SubscriptionStatusLabel({ status }: { status: string }) {
    const getLabelColor = () => {
        switch (status) {
            case Constants.SubscriptionStatus.Active:
                return 'uk-label-success';
            case Constants.SubscriptionStatus.Finished:
                return 'uk-label-secondary';
            case Constants.SubscriptionStatus.PendingPayment:
                return 'uk-label-warning';
            case Constants.SubscriptionStatus.Canceled:
                return 'uk-label-danger';
            default:
                return '';
        }
    }

    return (
        <>
            {status && <span className={`uk-label ${getLabelColor()}`}>
                {SubscriptionStatusDictionary[status] || ""}
            </span>}
        </>
    );
}