import React, { useEffect, useState } from "react";

interface iProps {
    /** json en formato string */
    jsonString?: string | null;
}

/**
 * Muestra una lista con clave valor del json dado como string.
 */
export default function JsonFormater({ jsonString }: iProps) {
    const [jsonData, setJsonData] = useState<Record<string, any>>({});

    useEffect(() => {
        try {
            if (jsonString) {
                const data = JSON.parse(jsonString);

                setJsonData(data);
            }
        } catch {

        }
    }, [jsonString]);

    const renderValue = (value: any) => {
        if (Array.isArray(value)) {
            const allSimpleValues = value.every(
                (item) => typeof item !== "object"
            );

            if (allSimpleValues) {
                return (
                    <ul className="uk-list uk-list-collapse">
                        <li className="uk-margin-small-left">
                            {value.map(item => item?.toString()).join(", ")}
                        </li>
                    </ul>
                );
            }

            return (
                <ul className="uk-list uk-list-collapse">
                    {value.map((item, index) => (
                        <li key={index} className="uk-margin-small-left uk-margin-small">
                            {typeof item === "object" ? (
                                <JsonFormater jsonString={JSON.stringify(item)} />
                            ) : (
                                item.toString()
                            )}
                        </li>
                    ))}
                </ul>
            );
        } else if (value && typeof value === "object") {
            return <JsonFormater jsonString={JSON.stringify(value)} />;
        } else {
            return value?.toString();
        }
    };

    return (
        <div>
            {jsonData && <>
                <ul className="uk-list uk-list-collapse uk-list-circle uk-margin-remove-vertical">
                    {Object.entries(jsonData).map(([key, value]) => (
                        <li key={key} className="uk-margin-small-left">
                            <strong>{key}:</strong> {renderValue(value)}
                        </li>
                    ))}
                </ul>
            </>}
        </div>
    );
}