import React from "react";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";

/**  Página que muestra los detalles de un participante en un curso */
export default function ParticipantDetails() {
    UsePageTitle('Detalles del alumno');

    return (
        <Content>
            <Heading text="CourseName: ParticipantName" />

            <div>
                <p>Contenido</p>
            </div>
        </Content>
    );
}