import React, { useEffect, useRef } from "react";
import TomSelectInstance from "tom-select";
import { RecursivePartial, TomInput, TomSettings } from "tom-select/dist/types/types";
import { iSelectOption } from "../interfaces/selectOption";

export interface iTomSelectOption {
    text: string;
    value: string;
}

interface iTomSelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
    options?: iTomSelectOption[];
    items?: string[];
    allowEmptyOption?: boolean;
    create?: boolean;
    onValueChange?: (value: string[]) => void;
}

export default function TomSelect({ options, items, allowEmptyOption = false, create, onValueChange, ...props }: iTomSelectProps) {
    const input = useRef<HTMLSelectElement>(null);
    const tomSelect = useRef<TomSelectInstance>();
    const changeFunction = useRef<(value: string[]) => void>();

    const createComponent = () => {
        if (tomSelect.current) {
            tomSelect.current.destroy();
        }

        if (items && items[0]) {
            if (!options) options = [];

            items.forEach(m => {
                if (!options?.some(opt => opt.value == m)) {
                    options?.push({ text: m, value: m });
                }
            });
        }

        const settings: RecursivePartial<TomSettings> = {
            options: options as RecursivePartial<iTomSelectOption[]>,
            items: items,
            sortField: {
                field: 'text',
                direction: 'asc'
            },
            onChange: () => {
                if (typeof changeFunction.current == "function") {
                    const value = tomSelect.current?.getValue() ?? '';
                    const arrayValue = value instanceof Array ? value : [value];

                    changeFunction.current(arrayValue);
                }
            }
        }

        if (typeof allowEmptyOption != "undefined") settings.allowEmptyOption = allowEmptyOption;
        if (typeof create != "undefined") settings.create = create;

        settings.plugins = {
            'dropdown_input': {}
        }

        if (props.multiple) {
            settings.plugins['remove_button'] = {
                title: 'Eliminar',
            };

            settings.onItemAdd = () => {
                tomSelect.current?.setTextboxValue('');
                tomSelect.current?.refreshOptions();
            }
        }

        tomSelect.current = new TomSelectInstance(input.current as TomInput, settings);
    };

    const sameOptions = (arr1: iTomSelectOption[], arr2: iTomSelectOption[]): boolean => {
        if (arr1.length !== arr2.length) return false;

        const sortedArr1 = [...arr1].sort((a, b) => a.value.localeCompare(b.value));
        const sortedArr2 = [...arr2].sort((a, b) => a.value.localeCompare(b.value));

        return sortedArr1.every((item, index) => item.text === sortedArr2[index].text && item.value === sortedArr2[index].value);
    }

    const sameItems = (arr1: string[], arr2: string[]): boolean => {
        if (arr1.length !== arr2.length) return false;

        const sortedArr1 = [...arr1].sort();
        const sortedArr2 = [...arr2].sort();

        return sortedArr1.every((value, index) => value === sortedArr2[index]);
    }

    useEffect(() => {
        changeFunction.current = onValueChange;
        createComponent();
    }, []);

    useEffect(() => {
        const newItems = items && items[0] ? items : []; // el algunos casos se manda el primer elemento como vacio, por eso este tipo de validaciones...
        const newOptions = options && options[0] ? options : [];

        // cuando se agregan nuevos elementos el componente si los contempla pero no vienen en los props, 
        // por eso es que se tienen que agregar aquí
        newItems.forEach(item => {
            if (!newOptions.some(option => option.value == item)) {
                newOptions.push({ text: item, value: item });
            }
        });

        const currentOptions: iTomSelectOption[] = Object.values(tomSelect.current?.options || []).map(option => {
            return {
                text: option.text,
                value: option.value
            };
        });

        const currentItems: string[] = Object.values(tomSelect.current?.items || []).map(item => item);

        if (sameOptions(newOptions, currentOptions) && sameItems(newItems, currentItems)) {
            return;
        }

        createComponent();
    }, [options, items]);

    useEffect(() => {
        if (tomSelect.current?.settings.allowEmptyOption == allowEmptyOption &&
            tomSelect.current?.settings.create == create) return;

        createComponent();
    }, [allowEmptyOption, create]);

    useEffect(() => {
        if (changeFunction.current?.toString() === onValueChange?.toString()) return;

        changeFunction.current = onValueChange;

        createComponent();
    }, [onValueChange]);

    for (const key in props) {
        if (key.startsWith("on")) {
            // elimina todos los eventos
            delete props[key as keyof typeof props];
            console.warn("No estan soportados los eventos del elemento <select>")
        }
    }

    return (
        <select ref={input} {...props}></select>
    );
}