import React, { useEffect, useState } from "react";

interface iTextToHtmlProps {
    /** Texto a formatear */
    text: string;

    /** 
     * Indica si se quiere tomar en cuenta las tabulaciones y espacios.
     * 
     * En caso de ser false o undefinen solo toma en cuenta los saltos de linea.
     */
    strict?: boolean;
}

/** Formatea un texto en contenido HTML mostando los saltos de linea.
 * 
 * En modo estricto toma en cuenta espacios y tabulaciones.
 */
export default function TextToHtml({ text, strict = false }: iTextToHtmlProps) {
    const [formattedText, setFormattedText] = useState<string>('');

    const formatTextToHtml = (text: string, strict: boolean = false) => {
        let newText = text.trim();

        // Reemplazar saltos de línea por <br/>
        newText = newText.replace(/\r\n?|\n/g, "<br/>");

        if (strict) {
            // Reemplazar tabulaciones y espacios
            newText = newText.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
            newText = newText.replace(/\s/g, "&nbsp;");
        }

        return newText;
    };

    useEffect(() => {
        setFormattedText(formatTextToHtml(text, strict));
    }, [text, strict]);

    return (
        <span
            className="uk-text-break"
            dangerouslySetInnerHTML={{ __html: formattedText }} // Permite mostrar el contenido con el formato dado.
        />
    );
};