import React from "react";
import Content from "../components/content";
import Heading from "../components/heading";
import UsePageTitle from "../components/usePageTitle";

// TODO: Confirmar que la URL de la página será la ingresada en el primer parrafo.

/** Página con los términos y condiciones */
export default function TermsAndConditions() {
    UsePageTitle('Términos y condiciones');

    return (
        <Content>
            <Heading text="Términos y condiciones" />
            <p className="uk-text-justify">
                www.lynxskatehouse.com, lynxskatehouse.com en lo sucesivo la "PLATAFORMA" es una plataforma online de venta de equipo para patinaje recreativo en lo sucesivo "LYNX" que facilita a sus visitantes y usuarios registrados en lo sucesivo "USUARIOS" establecer contacto entre pares o entre clientes y/o proveedores comerciales. Todo uso de la "PLATAFORMA" debe ser de acuerdo a lo establecido. Al usar la "PLATAFORMA" queda entendido que los "USUARIOS" aceptan estos términos.
            </p>
            <p>
                <strong>
                    USO PERSONAL Y NO COMERCIAL
                </strong>
            </p>
            <p className="uk-text-justify">
                Los servicios y contenido de esta "PLATAFORMA" pueden ser usados solo para uso personal. No está permitido modificar, distribuir, copiar, transmitir, desplegar, reproducir, publicar, o vender cualquier información sobre esta "PLATAFORMA" o cualquiera de los productos o servicios incluidos, a menos que sea especificado por escrito por el autor.
            </p>
            <p>
                <strong>
                    REFERENCIAS A LYNX
                </strong>
            </p>
            <p className="uk-text-justify">
                Por medio de "links" o "accesos", lo invitamos a referenciar a la "PLATAFORMA" como un medio que complementa, auxilia o le proporciona algún servicio o producto en la resolución de una situación tecnológica. A menos que sea especificado por "LYNX" cualquier otro medio de referencia es estrictamente prohibido, por lo que tampoco podrán ser copiados logos, gráficos, sonidos o imágenes de la "PLATAFORMA".
            </p>
            <p>
                <strong>
                    USUARIOS, CUENTAS, CONTRASEÑAS Y SEGURIDAD
                </strong>
            </p>
            <p className="uk-text-justify">
                En el evento de crear una nueva cuenta, debe completar el proceso de registro con datos actuales, completos y verídicos como le es requerido por la forma de registro de su cuenta. Al escoger un nombre de usuario y contraseña, se hace completamente responsable por mantener la confidencialidad de su cuenta, contraseña y de todas las actividades que ocurran bajo su nombre de usuario. Accede por lo tanto a notificar inmediatamente a "LYNX" cualquier uso no autorizado de su cuenta o intromisión de seguridad.
            </p>
            <p className="uk-text-justify">
                "LYNX" no será responsable por cualquier pérdida que pueda resultar por el uso indebido o no autorizado de su cuenta. No se deberá usar una cuenta ajena en ningún momento, sin la autorización o permiso del dueño de la cuenta.
            </p>
            <p className="uk-text-justify">
                Así mismo, al hacer uso de la "PLATAFORMA", el "USUARIO" acepta que "LYNX" recopile información de su actividad en la misma con propósitos estadísticos o para ofrecer un mejor servicio, entre otros.
            </p>
            <p>
                <strong>
                    CONTENIDO
                </strong>
            </p>
            <p className="uk-text-justify">
                El "USUARIO" se obliga a usar los "CONTENIDOS" de forma diligente, correcta, licita y en particular, se compromete a abstenerse de: (a) utilizar los "CONTENIDOS" de forma, con fines o efectos contrarios a la ley, a la moral, las buenas costumbres o el orden público; (b) copiar, reproducir, o distribuir con fines de lucro los "CONTENIDOS", a menos que se cuente con la autorización de "LYNX" o del titular de los correspondientes derechos o ello resulte legalmente permitido; (c) modificar o manipular las marcas, logotipos, avisos comerciales, nombres comerciales y signos distintivos en general de "LYNX", de la "PLATAFORMA" o de las personas vinculadas directa o indirectamente con "LYNX" (salvo que cuente con su autorización por escrito), (d) suprimir, eludir o modificar los "CONTENIDOS", así como los dispositivos técnicos de protección, o cualquier mecanismo o procedimiento establecido en la "PLATAFORMA".
            </p>
            <p>
                <strong>
                    USOS LEGALES PERMITIDOS
                </strong>
            </p>
            <p className="uk-text-justify">
                Como una condición del uso de los servicios, estos no deberán serán usados para propósitos ilegales o prohibidos por estos términos o condiciones. Estos servicios no serán usados de cualquier manera que puedan dañar, deshabilitar, o afectar de manera negativa cualquier servidor o Redes conectados con los servidores de "LYNX", o en su defecto interferir la productividad de cualquier otro "USUARIO" en estos servicios. No está permitido intentar obtener acceso no autorizado a cualquier servicio, cuentas, sistemas computacionales o redes conectadas a cualquier servidor de "LYNX" a través de Hacking, uso de contraseñas aleatorias o cualquier otro medio. No se permite obtener o intentar adquirir cualquier material o información por cualquier otro medio que no sea intencionalmente publicado en la "PLATAFORMA".
            </p>
            <p>
                <strong>
                    RESPONSABILIDAD
                </strong>
            </p>
            <p className="uk-text-justify">
                "LYNX", no se hace responsable por errores tipográficos publicados en cualquier página Web o Servidor del mismo, así como la renovación de contenido, promociones o información general sin previo aviso.
            </p>
            <p className="uk-text-justify">
                Todas las Marcas y Productos mencionados son respectivamente propiedad Intelectual de dichas empresas.
            </p>
        </Content>
    );
}