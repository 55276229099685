import "cleave.js/dist/addons/cleave-phone.mx.js";
import Cleave from "cleave.js/react";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import UIkit from "uikit";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";
import { iStatus } from "../../interfaces/status";
import { iUpdateData } from "../../interfaces/user";
import { useUser } from "../../userContext";

/**
 * Página para actualizar los datos del usuario
 * 
 * @returns
 */
export default function UpdateData() {
    const { user, updateUser } = useUser();
    const navigate = useNavigate();
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

    const [model, setModel] = useState<iUpdateData>({
        firstName: user?.name || '',
        lastName: user?.lastName || '',
        phone: user?.phone || '',
        email: user?.email || ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setModel(prevModel => ({
            ...prevModel,
            [name]: value
        }));
    };

    /** Función que maneja el cambio del componente InputPhone */
    const handleInputPhone = (name: string, value: string) => {
        setModel(prevModel => ({
            ...prevModel,
            [name]: value
        }));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model)
        };

        setFormSubmitted(true);

        fetch('/api/Me/Data/Update', requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                setErrors({});

                if (status.success) {
                    navigate("/");
                    updateUser();
                };

                status.messages?.forEach(mesagge => {
                    UIkit.notification({
                        message: `${mesagge}`,
                        status: status.success ? 'success' : 'danger',
                        pos: 'top-center'
                    });
                });

                if (status.errors) setErrors(data.errors);
            })
            .finally(() => {
                setFormSubmitted(false);
            });
    }

    UsePageTitle('Actualización de datos');

    return (
        <Content>
            <Heading text="Actualización de datos" />

            <div className="uk-grid-large" data-uk-grid>
                <div className="uk-width-1-1 uk-width-2-3@s">
                    <form action="/" method="post" onSubmit={handleSubmit} className="uk-form-horizontal uk-margin-large">
                        <fieldset className="uk-fieldset" disabled={formSubmitted}>
                            <div className="uk-margin-small">
                                <label className="uk-form-label" htmlFor="firstName">Tu nombre:</label>
                                <div className="uk-form-controls">
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        autoComplete="firstName"
                                        maxLength={255}
                                        className="uk-input"
                                        value={model.firstName}
                                        onChange={handleChange}
                                        required />

                                    {errors?.FirstName && errors.FirstName.map((error, index) => (
                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                    ))}
                                </div>
                            </div>
                            <div className="uk-margin-small">
                                <label className="uk-form-label" htmlFor="lastName">Tu apellido:</label>
                                <div className="uk-form-controls">
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        autoComplete="cc-family-name"
                                        maxLength={255}
                                        className="uk-input"
                                        value={model.lastName}
                                        onChange={handleChange} />

                                    {errors?.LastName && errors.LastName.map((error, index) => (
                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                    ))}
                                </div>
                            </div>
                            <div className="uk-margin-small">
                                <label className="uk-form-label" htmlFor="phone">Tu teléfono:</label>
                                <div className="uk-form-controls">
                                    <Cleave
                                        id="phone"
                                        name="phone"
                                        value={model.phone}
                                        autoComplete="tel"
                                        className="uk-input"
                                        options={{
                                            phone: true,
                                            phoneRegionCode: 'MX'
                                        }}
                                        onChange={(e: ChangeEvent) => handleInputPhone("phone", (e.target as any).rawValue)}
                                    />

                                    {errors?.Phone && errors.Phone.map((error, index) => (
                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                    ))}
                                </div>
                            </div>
                            <div className="uk-margin-small">
                                <label className="uk-form-label" htmlFor="email">Tu E-Mail:</label>
                                <div className="uk-form-controls">
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        autoComplete="email"
                                        maxLength={255}
                                        className="uk-input"
                                        value={model.email}
                                        onChange={handleChange}
                                        required />

                                    {errors?.Email && errors.Email.map((error, index) => (
                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                    ))}
                                </div>
                            </div>
                        </fieldset>
                        <button type="submit" className="uk-button uk-button-primary" disabled={formSubmitted}>
                            {formSubmitted ? "Guardando..." : "Guardar"}
                        </button>
                    </form>
                </div>
            </div>
        </Content>
    );
}