import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";

export interface iUserInfo {
    id: string;
    name: string;
    lastName?: string;
    email?: string;
    phone?: string;
    roles: string[];
}

const UserContext = createContext<{
    /** Datos del usuario. Si no ha iniciado sesión el valor será null */
    user: iUserInfo | null;

    /** Actualiza los datos del usuario. */
    updateUser: () => void;

    /** Cierra la sesión del usuario. */
    logOut: () => void;
}>({
    user: null,
    updateUser: () => { },
    logOut: () => { }
});

/**
 * Hook para usar el contexto del usuario.
 * @returns
 */
export const useUser = () => useContext(UserContext);

/**
 * Componente con el proveedor del contexto del usuario.
 * @returns
 */
export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<iUserInfo | null>(null);

    /** estado del componente */
    const state = useRef<"" | "loading" | "done">("");

    /** Se utiliza para las rutas protegidas, ya que el fetch puede no ser lo suficientemente rapido para cambiar el valor del user antes de entrar en una ruta protegida */
    const [loading, setLoading] = useState(true);

    // Obtiene los datos del usuario al cargar el componente.
    useEffect(() => {
        fetchUserData();
    }, []);

    /** Obtiene los datos del usuario desde la api. */
    async function fetchUserData() {
        if (state.current == "loading") return;

        setLoading(() => {
            state.current = "loading";
            return true;
        });

        fetch('/api/me')
            .then(response => response.json())
            .then(result => {
                setUser(() => {
                    setLoading(false);

                    if (result.id) {
                        return result as iUserInfo;
                    } 

                    return null;
                });
            })
            .catch(() => setUser(null))
            .finally(() => {
                state.current = "done";
            });

        //const request = await fetch("/api/me");

        //if (!request.redirected) {
        //    const response = await request.json();

        //    setUser(() => {
        //        setLoading(false);
        //        return response as iUserInfo;
        //    });
        //}
    }

    /** Actualiza los datos del usuario. */
    async function updateUser() {
        await fetchUserData();
    }

    /** Cierra la sesión del usuario. */
    function logOut() {
        window.location.href = "/salir";
        setUser(null);
    }

    return (
        <UserContext.Provider value={{ user, updateUser, logOut }}>
            {!loading && children}
        </UserContext.Provider>
    );
};