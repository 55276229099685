import { mdiAccountCircle, mdiAccountCircleOutline, mdiCart, mdiChartTimelineVariantShimmer, mdiRollerblade, mdiSchoolOutline, mdiSkateboarding } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link } from "react-router-dom";

/** Iconos disponibles */
type iconType = 'mdiAccountCircle'
    | 'mdiAccountCircleOutline'
    | 'mdiCart'
    | 'mdiChartTimelineVariantShimmer'
    | 'mdiRollerblade'
    | 'mdiSchoolOutline'
    | 'mdiSkateboarding';

/** Path de los iconos disponibles */
type iconPath = typeof mdiAccountCircle
    | typeof mdiAccountCircleOutline
    | typeof mdiCart
    | typeof mdiChartTimelineVariantShimmer
    | typeof mdiRollerblade
    | typeof mdiSchoolOutline
    | typeof mdiSkateboarding;

/** Obtiene el path del icono disponible */
const iconMap: { [key in iconType]: iconPath } = {
    mdiAccountCircle,
    mdiAccountCircleOutline,
    mdiCart,
    mdiChartTimelineVariantShimmer,
    mdiRollerblade,
    mdiSchoolOutline,
    mdiSkateboarding
};

/**
 * Propiedades del componenete
 */
export interface iSectionCardItem {
    /** Icono a mostrar */
    icon: iconType;

    /** Título de la sección */
    header: string;

    /** Descripción de la sección */
    description: string;

    /** Link hacia la sección */
    link: string;
}

/**
 * Componente que muestra una card con información de una sección.
 */
export default function SectionCard({ item }: { item: iSectionCardItem }) {
    return (
        <>
            <Link to={item.link}
                className="uk-display-block uk-tile uk-background-muted uk-box-shadow-hover-large">
                <div className="uk-grid uk-flex-middle" data-uk-grid="">
                    <div className="uk-width-1-1 uk-width-auto@m">
                        <span>
                            <Icon path={iconMap[item.icon]} size={5} style={{ color: '#000' }} />
                        </span>
                    </div>
                    <div className="uk-width-expand">
                        <h2>
                            {item.header}
                        </h2>
                        <p className="uk-text-secondary">
                            {item.description}
                        </p>
                    </div>
                </div>
            </Link>
        </>
    );
}
