import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Content from "../../components/content";
import Heading from "../../components/heading";
import JsonFormater from "../../components/jsonFormater";
import TextToHtml from "../../components/textToHtml";
import UsePageTitle from "../../components/usePageTitle";
import { iLogDetails } from "../../interfaces/log";
import { formatDate } from "../../utils";

/** Página para ver los detalletes un curso */
export default function LogDetails() {
    const [log, setLog] = useState<iLogDetails | null>(null);
    const { id } = useParams<{ id: string }>();

    const { state } = useLocation() as {
        state: { log: iLogDetails } | null;
    };

    useEffect(() => {
        if (state?.log) {
            setLog(state.log);
        } else {
            fetch(`/api/Log/Details/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    setLog(data as iLogDetails);
                });
        }
    }, [id, state]);

    UsePageTitle('Detalles del log');

    return (
        <>
            <Content>
                <Heading text="Detalles del log" backOption={"/logs"} />

                <div>
                    <dl>
                        {log?.userName && <>
                            <div className="uk-margin-small-bottom">
                                <dt>
                                    Usuario:
                                </dt>
                                <dd>
                                    {log?.userName}
                                </dd>
                            </div>
                        </>}

                        <div className="uk-margin-small-bottom">
                            <dt>
                                Título:
                            </dt>
                            <dd>
                                {log?.title}
                            </dd>
                        </div>


                        {log?.description && <>
                            <div className="uk-margin-small-bottom">
                                <dt>
                                    Descrición
                                </dt>
                                <dd>
                                    <TextToHtml text={log?.description} />
                                </dd>
                            </div>
                        </>}

                        <div className="uk-margin-small-bottom">
                            <dt>
                                Nivel:
                            </dt>
                            <dd>
                                {log?.level}
                            </dd>
                        </div>

                        <div className="uk-margin-small-bottom">
                            <dt>
                                Fecha:
                            </dt>
                            <dd>
                                {formatDate(log?.createdTime, true)}
                            </dd>
                        </div>

                        {log?.formData && <>
                            <div className="uk-margin-small-bottom">
                                <dt>
                                    Datos de formulario:
                                </dt>
                                <dd>
                                    <JsonFormater jsonString={log.formData} />
                                </dd>
                            </div>
                        </>}
                    </dl>
                </div>
            </Content>
        </>
    );
}