import React from "react";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

/** Interfaz con las propiedades para una páginación */
interface iPaginationProps {
    /** Página actual */
    currentPage: number;

    /** Páginas totales a mostrar */
    totalPages: number;

    /** Función para manejar la paginación */
    onPageChange: (page: number) => void;
}

/**
 * Componente que muestra una paginación
 */
export default function Pagination({ currentPage, totalPages, onPageChange }: iPaginationProps) {
    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    // Almacena un array con los números de página
    const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

    return (
        <nav aria-label="Paginación">
            <ul className="uk-pagination uk-flex-center">
                <li>
                    <button
                        className="uk-button uk-button-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <Icon path={mdiChevronLeft} size={1} />
                    </button>
                </li>
                {pages.map(page => (
                    <li key={page} className={page === currentPage ? 'uk-active' : ''}>
                        <button
                            className="uk-button uk-button-link"
                            onClick={() => handlePageChange(page)}
                        >
                            {page}
                        </button>
                    </li>
                ))}
                <li>
                    <button
                        className="uk-button uk-button-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <Icon path={mdiChevronRight} size={1} />
                    </button>
                </li>
            </ul>
        </nav>
    );
}
