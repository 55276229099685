import { mdiArrowLeft } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link } from "react-router-dom";
import { iCourseDetails } from "../interfaces/course";
import { formatCurrencyMXN } from "../utils";
import TextToHtml from "./textToHtml";

/** Interfaz con las propiedades para el componente */
interface iSelectedCourseInfoProps {
    /** Modelo con los datos de una acedmia */
    course: iCourseDetails;

    /** Indica si se mostrará el botón para seleccionar otra curso */
    btnSelectAnotherCourse?: boolean;

    /** Indica si se mostrará el botón para ver las suscripciones del usuario */
    btnMySubscription?: boolean;
}

/**
 * Pequeña card con información básica del curso seleccionada
 * 
 * @returns
 */
export default function SelectedCourseInfo({ course, btnSelectAnotherCourse = false, btnMySubscription = false }: iSelectedCourseInfoProps) {
    // TODO: limitar el máximo de caracteres a mostrar en la descripción o cambiarla por otra cosa

    return (
        <article className="uk-card uk-card-default">
            <div className="uk-card-body">
                <p className="uk-text-muted uk-margin-small">
                    Curso Seleccionado:
                </p>
                <h4 className="uk-margin-small">
                    {course.title}
                </h4>
                <div className="uk-margin-small uk-panel uk-height-max-medium uk-overflow-auto">
                    <p>
                        <TextToHtml text={course.description} />
                    </p>
                </div>
                <hr />
                <div className="uk-flex uk-flex-between">
                    <div className="uk-h5 uk-margin-remove">
                        {course.singlePayment ? "Precio" : "Precio por clase"}
                    </div>
                    <div className="uk-h5 uk-margin-remove uk-text-primary">
                        {formatCurrencyMXN(course.price ?? 0)}
                    </div>
                </div>

                {btnSelectAnotherCourse && <Link to="/academia" className="uk-button uk-button-default uk-width-1-1 uk-margin-medium-top">
                    <Icon path={mdiArrowLeft} size={1} /> Elegir otro curso
                </Link>}

                {btnMySubscription && <Link to="/suscripciones" className="uk-button uk-button-primary uk-width-1-1 uk-margin-medium-top">
                    Ir a mis suscripciones
                </Link>}

            </div>
        </article>
    );
}