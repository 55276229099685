import React, { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UIkit from "uikit";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";
import { iStatus } from "../../interfaces/status";
import { iRegister } from "../../interfaces/user";
import { useUser } from "../../userContext";
import { mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";

/** Datos iniciales para el formulario de registro */
const initialRegisterdata: iRegister = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
}

/** Página para registrarse como nuevo usuario */
export default function Register() {
    const { updateUser } = useUser();
    const navigate = useNavigate();
    const [model, setModel] = useState<iRegister>(initialRegisterdata);
    const [state, setState] = useState<'' | 'sending' | 'sent'>('');
    const [acceptedConditions, setAcceptedConditions] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        setErrors({});

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model)
        };

        setState('sending');

        fetch(`/api/user/newuser?conditionsAcceptation=${acceptedConditions}`, requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                if (status.success) {
                    navigate("/");
                    updateUser();
                }

                status.messages?.forEach(mesagge => {
                    UIkit.notification({
                        message: `${mesagge}`,
                        status: status.success ? 'success' : 'danger',
                        pos: 'top-center'
                    });
                });

                if (status.errors) setErrors(data.errors);

            })
            .finally(() => {
                setState('sent');
            });
    }

    /** Función que maneja el cambio de los inputs */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setModel(prevModel => ({
            ...prevModel,
            [name]: value
        }));
    };

    UsePageTitle('Registro de usuarios');

    return (
        <Content>
            <Heading text="Crea una cuenta con nosotros" />
            <div className="uk-grid-large" data-uk-grid>
                <div className="uk-width-1-1 uk-width-2-3@s">
                    <form action="/" method="post" onSubmit={handleSubmit} className="uk-form-horizontal uk-margin-large">
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="name">Nombre:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    autoComplete="given-name"
                                    className="uk-input"
                                    value={model.name}
                                    onChange={handleChange}
                                    required />

                                {errors?.Name && errors.Name.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="lastName">Apellidos:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    autoComplete="family-name"
                                    value={model.lastName}
                                    onChange={handleChange}
                                    className="uk-input" />

                                {errors?.LastName && errors.LastName.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="email">E-Mail:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    value={model.email}
                                    onChange={handleChange}
                                    className="uk-input"
                                    required />

                                {errors?.Email && errors.Email.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="password">Contraseña:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    autoComplete="new-password"
                                    value={model.password}
                                    onChange={handleChange}
                                    className="uk-input"
                                    required />

                                {errors?.Password && errors.Password.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="confirmPassword">Confirma tu contraseña:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    autoComplete="new-password"
                                    value={model.confirmPassword}
                                    onChange={handleChange}
                                    className="uk-input"
                                    required />

                                {errors?.ConfirmPassword && errors.ConfirmPassword.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin uk-child-width-auto">
                            <label>
                                <input
                                    type="checkbox"
                                    className="uk-checkbox"
                                    id="conditionsAcceptation"
                                    name="conditionsAcceptation"
                                    checked={acceptedConditions}
                                    onChange={(e) => setAcceptedConditions(e.target.checked)}
                                    required />
                                &nbsp;
                                Acepto que he leído y estoy de acuerdo con los <Link to="/terminos-y-condiciones" target="_blank">términos y condiciones de uso <Icon path={mdiOpenInNew} size={.75} /></Link> y la <Link to="/politica-de-privacidad" target="_blank">politica de privacidad <Icon path={mdiOpenInNew} size={.75} /></Link>
                            </label>
                        </div>
                        <button type="submit" className="uk-button uk-button-primary" disabled={state == 'sending'}>
                            {state == 'sending' ? "Registrando..." : "Registrarme"}
                        </button>
                    </form>
                </div>
                <div className="uk-width-1-1 uk-width-1-3@s">
                    <p>
                        <strong>
                            Con tu cuenta podrás:
                        </strong>
                    </p>
                    <ul className="uk-list uk-list-disc uk-list-collapse">
                        <li>
                            Realizar compras de manera rápida y sencilla
                        </li>
                        <li>
                            Revisar tu historial de pedidos y hacer un seguimiento de los mismos
                        </li>
                        <li>
                            Recibir ofertas exclusivas y noticias sobre nuevos productos
                        </li>
                        <li>
                            Suscribirte a nuestras clases y hacer un seguimiento de tu avance
                        </li>
                    </ul>
                    <p>
                        ¿Ya tienes una cuenta? <a href="/identificacion" title="Inicio de sesión" data-uk-tooltip="">Inicia sesión</a>
                    </p>
                </div>
            </div>
        </Content>
    );
}