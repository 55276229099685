import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Content from "../../components/content";
import Heading from "../../components/heading";
import Pagination from "../../components/pagination";
import UsePageTitle from "../../components/usePageTitle";
import { iPagination } from "../../interfaces/pagination";
import { iUserIndex } from "../../interfaces/user";
import { formatConjunction, formatDate, getPaginationRangeText, translateRoles } from "../../utils";
import UserStatusLabel from "../../components/userStatusLabel";

/** Página para mostrar el index de los usuarios  */
export default function User() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState<iUserIndex | null>(null);
    const [pagination, setPagination] = useState<iPagination | null>(null);
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        const paginationParams = new URLSearchParams(Object.entries(pagination ?? {}))

        const paginationQueryString = paginationParams.toString();

        let params = '';

        if (paginationQueryString) {
            if (params) {
                params += `&${paginationQueryString}`;
            } else {
                params += paginationQueryString;
            }
        }

        if (location.search != params) {
            setQuery(params);
            navigate({ search: params });
        }
    }, [pagination]);

    useEffect(() => {
        fetch(`/api/Admin/User/Index?${query}`)
            .then(res => res.json())
            .then(data => {
                setData(data as iUserIndex);
            });
    }, [query]);

    useEffect(() => {
        const search = location.search.slice(1);

        if (search != query) {
            setQuery(search);
        }
    }, [location.search])

    const handlePageChange = (page: number) => {
        setPagination({
            ...pagination,
            currentPage: page
        });
    };

    UsePageTitle('Administrar usuarios');

    return (
        <Content>
            <Heading text="Administrar usuarios" createOption="/usuarios/nuevo" />

            <div className="uk-margin">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                    <div>
                        {getPaginationRangeText(data?.pagination?.currentPage, data?.pagination?.itemsPerPage, data?.totalItems)}
                    </div>
                </div>
            </div>

            <div className="uk-margin">
                <div className="uk-card uk-card-small uk-card-default uk-flex uk-flex-column">
                    <ul className="uk-list uk-list-divider uk-margin-remove">
                        {data && data.list && data.list?.length > 0 && data.list.map(user => (
                            <li key={user.id} className="uk-padding-small uk-margin-remove">
                                <div className="uk-flex-1">
                                    <div className="uk-link-heading uk-flex uk-flex-between">
                                        <h2 className="uk-h6 uk-margin-remove-vertical">
                                            <Link to={`/usuarios/${user.id}`} state={{ user }}>
                                                {user.name}
                                            </Link>
                                        </h2>

                                        <div>
                                            <UserStatusLabel status={user.status ?? ''} />
                                        </div>
                                    </div>
                                    <ul className="uk-subnav uk-margin-xsmall-vertical" data-uk-margin>
                                        <li>
                                            <div>
                                                <strong>Roles:</strong>
                                                {formatConjunction(translateRoles(user.roles))}
                                            </div>
                                        </li>
                                        {user.lastVisit && <li>
                                            <div>
                                                <strong>Último ingreso:</strong> {formatDate(user.lastVisit)}
                                            </div>
                                        </li>}
                                    </ul>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {data && data.totalItems && data.totalPages && data.totalItems > 0 && data.totalPages > 1 && <section className="uk-section uk-section-small">
                <Pagination
                    currentPage={data.pagination.currentPage ?? 1}
                    totalPages={data.totalPages}
                    onPageChange={handlePageChange}
                />
            </section>}
        </Content>
    );
}