import React from "react";
import Content from "../../components/content";
import CourseIndex from "../../components/courseIndex";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";

/** Página para administrar los cursos */
export default function CourseManage() {
    UsePageTitle('Administración de cursos');

    return (
        <Content>
            <Heading text="Administración de cursos" createOption="/clase/nueva" />
            <CourseIndex isFromManagePage={true} />
        </Content>
    );
}