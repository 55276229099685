import { mdiArrowLeft, mdiClose, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import UIkit from "uikit";
import { Heading } from "../interfaces/heading";
import { iStatus } from "../interfaces/status";

/** Componente que muestra un título de página y algunas opciones para realizar */
export default function Heading({ text, backOption, createOption, editOption, deleteOption, closeOption }: Heading) {
    const navigate = useNavigate();

    const handleBack = (url: string | -1) => {
        if (url == -1) {
            navigate(-1);
        } else {
            navigate(url);
        }
    }

    const handleDelete = (url: string) => {
        UIkit.modal.confirm(`
        <h4>¿Estás seguro de que deseas eliminar este registro?</h4>
        <p>Esta acción no se puede deshacer.<p>
        `).then(function () {
            fetch(url)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    const status = data as iStatus

                    if (status.success) handleBack(-1);

                    status.messages?.forEach(mesagge => {
                        UIkit.notification({
                            message: `${mesagge}`,
                            status: status.success ? 'success' : 'danger',
                            pos: 'top-center'
                        });
                    });
                });
        }, function () {
            // nothing to do...
        })
    }

    return (
        <div className="uk-margin-large uk-flex-middle" data-uk-grid="">
            <div className="uk-width-1-1 uk-width-4-5@s">
                <h1 className="uk-heading-bullet">
                    {text}
                </h1>
            </div>
            <div className="uk-width-expand">
                <ul className="uk-iconnav uk-flex-right">
                    {backOption && <li>
                        <a
                            onClick={() => handleBack(backOption)}
                            className="uk-text-secondary"
                            aria-label="Regresar"
                            data-uk-tooltip="Regresar"
                        >
                            <Icon path={mdiArrowLeft} size={1} />
                        </a>
                    </li>}
                    {createOption && <li>
                        <Link
                            to={createOption}
                            className="uk-text-secondary"
                            aria-label="Crear registro"
                            data-uk-tooltip="Crear registro"
                        >
                            <Icon path={mdiPlus} size={1} />
                        </Link>
                    </li>}
                    {editOption && <li>
                        <Link
                            to={editOption}
                            className="uk-text-secondary"
                            aria-label="Editar registro"
                            data-uk-tooltip="Editar registro"
                        >
                            <Icon path={mdiPencil} size={1} />
                        </Link>
                    </li>}
                    {deleteOption && <li>
                        <a
                            onClick={() => handleDelete(deleteOption)}
                            className="uk-text-secondary"
                            aria-label="Eliminar registro"
                            data-uk-tooltip="Eliminar registro"
                        >
                            <Icon path={mdiDelete} size={1} />
                        </a>
                    </li>}
                    {closeOption && <li>
                        <a
                            onClick={() => handleBack(closeOption)}
                            className="uk-text-secondary"
                            aria-label="Cerrar página"
                            data-uk-tooltip="Cerrar página"
                        >
                            <Icon path={mdiClose} size={1} />
                        </a>
                    </li>}
                </ul>
            </div>
        </div>
    );
}