import Cleave from "cleave.js/react";
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { iProductForm } from "../interfaces/product";
import { iSelectOption } from "../interfaces/selectOption";
import { iSizeGuide } from "../interfaces/sizeGuide";
import { iSpecification } from "../interfaces/specification";
import FileUpload, { FileStates } from "./fileUpload";
import InputSizeGuide from "./inputSizeGuide";
import InputSpecification from "./inputSpecification";
import TomSelect, { iTomSelectOption } from "./tomSelect";

/** Interfaz con las propiedades para el formulario de un producto */
interface iProductFormProps {
    /** 
     * Id del producto. Se manda en el caso de la edición .
     * 
     * NOTE: Se declara como string ya que el id es tomado de useParams y estos datos siempre son del tipo string.
     * */
    id?: string;

    /** Modelo que maneja los errores. Sus propiedades se manejan en PascalCase ya que dependen de los modelos en el back */
    errors: { [key: string]: string[] };

    /** Función para manejar el submit */
    onSubmit: (model: iProductForm) => void;

    /** Indica si se hizo submit */
    formSubmitted: boolean;
}

/** Datos iniciales para el producto */
const defaultProduct: iProductForm = {
    name: '',
    summary: '',
    category: '',
    tags: [],
    sku: '',
    price: null,
    onlyStudents: false,
    academyPomotionPrice: null,
    generalInformation: '',
    model: null,
    type: '',
    level: '',
    weight: null,
    generalSpecifications: '',
    specifications: [],
    shippingOptions: [],
    sizeGuides: []
}

export default function ProductForm({ id, errors, onSubmit, formSubmitted }: iProductFormProps) {
    const [productModel, setProductModel] = useState<iProductForm>(defaultProduct);

    const [formData, setFormData] = useState<{ categories: iTomSelectOption[], types: iTomSelectOption[], levels: iTomSelectOption[], tags: iTomSelectOption[] }>();

    /** 
     * estado del componente 
     * @description utiliza esta variable para determinar cual es el estado actual del componente
     * */
    const state = useRef<"" | "loading" | "loaded" | "saving" | "done">("");

    /** 
     * id del producto 
     * @description utiliza esta variable para determinar si el producto ha cambiado entre renderizados
     * */
    const idRef = useRef<string>("");

    /** 
     * url donde se despliega el componente 
     * @description utiliza esta variable para determinar si el componente a cambiado de página
     * */
    const href = useRef<string>();

    const getProductFormData = () => {
        if (state.current != "loading") return;

        fetch(`/api/Product/GetFormData/${idRef.current}`)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const product = data as iProductForm;
                setProductModel(product || []);
            });
    }

    const getFormData = () => {
        const process = [
            fetch('/api/Product/GetCategories')
                .then(res => res.json())
                .then(data => data?.map((item: iSelectOption): iTomSelectOption => {
                    return {
                        text: item.key,
                        value: item.value
                    }
                })),
            fetch('/api/Product/GetTypes')
                .then(res => res.json())
                .then(data => data?.map((item: iSelectOption): iTomSelectOption => {
                    return {
                        text: item.key,
                        value: item.value
                    }
                })),
            fetch('/api/Product/GetLevels')
                .then(res => res.json())
                .then(data => data?.map((item: iSelectOption): iTomSelectOption => {
                    return {
                        text: item.key,
                        value: item.value
                    }
                })),
            fetch('/api/Tag/GetTags')
                .then(res => res.json())
                .then(data => data?.map((item: iSelectOption): iTomSelectOption => {
                    return {
                        text: item.key,
                        value: item.value
                    }
                }))
        ];

        Promise.all(process).then(result => {
            setFormData(() => {
                state.current = "loaded";

                return {
                    categories: result[0],
                    types: result[1],
                    levels: result[2],
                    tags: result[3]
                };
            });
        });
    }

    useEffect(() => getFormData(), [productModel]);

    useEffect(() => {
        if (href.current != location.href) {
            state.current = "";
            href.current = location.href;
        }
    }, [location.href]);

    useEffect(() => {
        const newId = id || "";

        if (newId == idRef.current) {
            if (!newId) { // si no hay id, es un nuevo registro
                if (state.current == "") {
                    state.current = "loading";
                    getFormData();
                }
            }
        } else {
            idRef.current = newId;
            state.current = "loading";

            if (!newId) {
                getFormData();
            } else {
                getProductFormData();
            }
        }
    }, []);

    const getButtonText = () => {
        if (id) {
            return formSubmitted ? 'Actualizando...' : 'Actualizar';
        } else {
            return formSubmitted ? 'Guardando y Publicando...' : 'Guardar y Publicar';
        }
    };

    /** Función que maneja el cambio de los inputs y textarea en el modelo */
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target;

        let newValue: string | number | null = value;

        if (type == 'number') {
            newValue = value == '' ? null : Number(value);
        }

        setProductModel(prevProductModel => ({
            ...prevProductModel,
            [name]: newValue
        }));
    };

    /** Función que maneja el cambio de los inputs del componente Cleave - Currency */
    const handleInputCurrency = (name: string, value: string) => {
        setProductModel(prevProductModel => ({
            ...prevProductModel,
            [name]: Number(value) || null
        }));
    };

    /** Función que maneja el cambio del componente TomSelect */
    const handleSelectChange = (name: string, values: string | string[]) => {
        setProductModel(prevProductModel => ({
            ...prevProductModel,
            [name]: values,
        }));
    };

    /** Función que maneja el cambio de los inputs tipo checkbox */
    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;

        setProductModel(prevProductModel => ({
            ...prevProductModel,
            [name]: checked
        }));
    };

    const handleSpecificationChange = (value: iSpecification[]) => {
        setProductModel(prevProductModel => ({
            ...prevProductModel,
            specifications: value
        }));
    }

    const handleSizeGuideChange = (value: iSizeGuide[]) => {
        setProductModel(prevProductModel => ({
            ...prevProductModel,
            sizeGuides: value
        }));
    }

    /**
     * Maneja el cambio de estado del componente de las imagenes
     * @description el estado indica si el componente esta trabajando (subiendo una imagen) o no
     * @param state estado del componente
     */
    const handleFileStateChange = (state: FileStates) => {
        if (state == FileStates.Working) {

        } else {

        }
    }

    /**
     * Maneja el cambio en las imagenes
     * @param files lista de archivos
     */
    const handleFileChange = (files: string[]) => {
        setProductModel(prevModel => ({
            ...prevModel,
            media: files,
        }));
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        onSubmit(productModel);
    }

    if (idRef && (state.current == "" || state.current == "loading")) {
        return "Obteniendo información...";
    }

    return (
        <form onSubmit={handleSubmit} className="uk-form-stacked">
            <div className="uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                    <div className="uk-form-horizontal">
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="name">Nombre:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={productModel.name}
                                    className="uk-input"
                                    onChange={handleChange}
                                    required />

                                {errors?.Name && errors.Name.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="summary">Resumen:</label>
                            <div className="uk-form-controls">
                                <textarea
                                    id="summary"
                                    name="summary"
                                    value={productModel.summary}
                                    className="uk-textarea"
                                    onChange={handleChange}
                                    required />

                                {errors?.Summary && errors.Summary.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="category">Categoría:</label>
                            <div className="uk-form-controls">
                                <TomSelect
                                    id="category"
                                    name="category"
                                    options={formData?.categories}
                                    items={[productModel.category]}
                                    onValueChange={(value) => handleSelectChange("category", value[0] ?? '')}
                                    create
                                    required
                                />

                                {errors?.Category && errors.Category.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="tags">Etiquetas:</label>
                            <div className="uk-form-controls">
                                <TomSelect
                                    id="tags"
                                    name="tags"
                                    options={formData?.tags}
                                    items={productModel.tags}
                                    onValueChange={(value) => handleSelectChange("tags", value)}
                                    create
                                    multiple
                                />

                                {errors?.Tags && errors.Tags.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="sku">SKU:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="text"
                                    id="sku"
                                    name="sku"
                                    value={productModel.sku}
                                    className="uk-input"
                                    onChange={handleChange}
                                    required />

                                {errors?.SKU && errors.SKU.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="price">Precio Público:</label>
                            <div className="uk-form-controls">
                                <Cleave
                                    id="price"
                                    name="price"
                                    value={productModel.price || ''}
                                    required
                                    autoComplete="transaction-amount"
                                    className="uk-input"
                                    options={{
                                        numeral: true,
                                        numeralThousandsGroupStyle: "thousand",
                                        numeralPositiveOnly: true
                                    }}
                                    onChange={(e: ChangeEvent) => handleInputCurrency("price", (e.target as any).rawValue)}
                                />

                                {errors?.Price && errors.Price.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label>
                                <input
                                    type="checkbox"
                                    name="onlyStudents"
                                    className="uk-checkbox uk-margin-right"
                                    checked={productModel.onlyStudents}
                                    onChange={handleCheckboxChange} />

                                Producto disponible solo para alumnos de Lynx Academy
                            </label>

                            {errors?.OnlyStudents && errors.OnlyStudents.map((error, index) => (
                                <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                            ))}
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="academyPomotionPrice">Precio Promoción Lynx Academy:</label>
                            <div className="uk-form-controls">
                                <Cleave
                                    id="academyPomotionPrice"
                                    name="academyPomotionPrice"
                                    value={productModel.academyPomotionPrice || ''}
                                    autoComplete="transaction-amount"
                                    className="uk-input"
                                    options={{
                                        numeral: true,
                                        numeralThousandsGroupStyle: "thousand",
                                        numeralPositiveOnly: true
                                    }}
                                    onChange={(e: ChangeEvent) => handleInputCurrency("academyPomotionPrice", (e.target as any).rawValue)}
                                />

                                {errors?.AcademyPomotionPrice && errors.AcademyPomotionPrice.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                    <div className="uk-form-horizontal">
                        <div className="uk-margin-small">
                            <label className="uk-form-label">Fotos:</label>
                            <div className="uk-form-controls">
                                <FileUpload
                                    value={productModel?.media}
                                    onChange={handleFileChange}
                                    onChangeState={handleFileStateChange}
                                />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                    <legend className="uk-legend">
                        Descripción
                    </legend>

                    <div className="uk-form-horizontal">
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="generalInformation">Información General:</label>
                            <div className="uk-form-controls">
                                <textarea
                                    id="generalInformation"
                                    name="generalInformation"
                                    value={productModel.generalInformation}
                                    className="uk-textarea uk-height-small"
                                    onChange={handleChange}
                                    required />

                                {errors?.GeneralInformation && errors.GeneralInformation.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="model">Modelo:</label>
                            <div className="uk-form-controls">
                                <input
                                    type="number"
                                    id="model"
                                    name="model"
                                    step={1}
                                    value={productModel.model ?? ''}
                                    className="uk-input"
                                    onChange={handleChange}
                                    required />

                                {errors?.Model && errors.Model.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="type">Tipo:</label>
                            <div className="uk-form-controls">
                                <TomSelect
                                    id="type"
                                    name="type"
                                    options={formData?.types}
                                    items={[productModel.type]}
                                    onValueChange={(value) => handleSelectChange("type", value[0] ?? '')}
                                    create
                                    required
                                />

                                {errors?.Type && errors.Type.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="level">Nivel:</label>
                            <div className="uk-form-controls">
                                <TomSelect
                                    id="level"
                                    name="level"
                                    options={formData?.levels}
                                    items={[productModel.level]}
                                    onValueChange={(value) => handleSelectChange("level", value[0] ?? '')}
                                    create
                                    required
                                />

                                {errors?.Level && errors.Level.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="weight">Peso (gramos):</label>
                            <div className="uk-form-controls">
                                <input
                                    type="number"
                                    id="weight"
                                    name="weight"
                                    step={0.01}
                                    value={productModel.weight ?? ''}
                                    className="uk-input"
                                    onChange={handleChange}
                                    required />

                                {errors?.Weight && errors.Weight.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </fieldset>
                {/*<fieldset className="uk-fieldset" disabled={formSubmitted}>*/}
                {/*    <legend className="uk-legend">*/}
                {/*        Opciones de envío*/}
                {/*    </legend>*/}

                {/*    Input - ShippingOptions - Component*/}
                {/*</fieldset>*/}
                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                    <legend className="uk-legend">
                        Especificaciones
                    </legend>

                    <div className="uk-form-horizontal">
                        <div className="uk-margin-small">
                            <label className="uk-form-label" htmlFor="generalSpecifications">Generales:</label>
                            <div className="uk-form-controls">
                                <textarea
                                    id="generalSpecifications"
                                    name="generalSpecifications"
                                    value={productModel.generalSpecifications}
                                    className="uk-textarea uk-height-small"
                                    onChange={handleChange}
                                    required />

                                {errors?.GeneralSpecifications && errors.GeneralSpecifications.map((error, index) => (
                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                ))}
                            </div>
                        </div>

                        <InputSpecification
                            defaultValue={productModel.specifications}
                            onItemsChange={handleSpecificationChange} />

                    </div>
                </fieldset>
                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                    <legend className="uk-legend">
                        Guía de tamaño
                    </legend>

                    <InputSizeGuide
                        defaultValue={productModel.sizeGuides}
                        onItemsChange={handleSizeGuideChange} />
                </fieldset>
            </div>

            <button type="submit" className="uk-button uk-button-primary uk-margin-medium-top" disabled={formSubmitted}>
                {getButtonText()}
            </button>
        </form >
    );
}