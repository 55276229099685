/** 
 * Usuario general
 * 
 * Permite comprar productos e inscribir alumnos a un curso.
 */
export const GENERALUSER = 'GeneralUser';

/** 
 * Instructor
 * 
 * Permite administrar los alumnos de un curso.
 */
export const INSTRUCTOR = 'Instructor';

/** 
 * Capturista de productos
 * 
 * Permite administrar los productos.
 */
export const PRODUCTCAPTURIST = 'ProductCapturist';

/** 
 * Capturista de clases/cursos
 * 
 * Permite administrar las clases y suscripciones.
 */
export const CLASSCAPTURIST = 'ClassCapturist';

/** 
 * Administrador
 * 
 * Permite administrar los usuarios registrados.
 */
export const ADMIN = 'Admin';

export const Constants = {
    /** Estatus de una suscripción */
    SubscriptionStatus: {
        Active: "Active" as const,
        Finished: "Finished" as const,
        PendingPayment: "PendingPayment" as const,
        Canceled: "Canceled" as const,
    },
    /** Estatus de un usuario */
    UserStatus: {
        /** Activo */
        Active: "Activo" as const,

        /** Bloqueado */
        Blocked: "Bloqueado" as const,

        /** Suspendido / Bloqueado por el sistema */
        Suspended: "Suspendido" as const,

        /** Eliminado */
        Deleted: "Eliminado" as const,
    }
}