import React from "react";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";

/** Página que indica que el usuario debé estar identificado para poder realizar una compra */
export default function CartIdentification() {
    UsePageTitle('Carrito de compra');

    return (
        <Content>
            <Heading text="Carrito de compra" />

            <div>
                <p>Contenido</p>
            </div>
        </Content>
    );
}