import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import UIkit from "uikit";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";
import { iUpdatePassword } from "../../interfaces/user";
import { iStatus } from "../../interfaces/status";
import Content from "../../components/content";

const initialUpdatePasswordData: iUpdatePassword = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
}

/**
 * Página para actualizar la contraseña del usuario
 * 
 * @returns
 */
export default function UpdatePassword() {
    const navigate = useNavigate();
    const [model, setModel] = useState<iUpdatePassword>(initialUpdatePasswordData);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setModel({
            ...model,
            [name]: value
        });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model)
        };

        setFormSubmitted(true);

        fetch('/api/Me/Password/Update', requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                setErrors({});

                if (status.success) navigate("/");

                status.messages?.forEach(mesagge => {
                    UIkit.notification({
                        message: `${mesagge}`,
                        status: status.success ? 'success' : 'danger',
                        pos: 'top-center'
                    });
                });

                if (status.errors) setErrors(data.errors);
            })
            .finally(() => {
                setFormSubmitted(false);
            });
    }

    UsePageTitle('Actualización de Contraseña');

    return (
        <Content>
            <Heading text="Actualización de Contraseña" />

            <div className="uk-grid-large" data-uk-grid>
                <div className="uk-width-1-1 uk-width-2-3@s">
                    <form onSubmit={handleSubmit} className="uk-form-horizontal uk-margin-large">
                        <fieldset className="uk-fieldset" disabled={formSubmitted}>
                            <div className="uk-margin-small">
                                <label className="uk-form-label" htmlFor="oldPassword">Contraseña Actual:</label>
                                <div className="uk-form-controls">
                                    <input
                                        type="password"
                                        id="oldPassword"
                                        name="oldPassword"
                                        autoComplete="current-password"
                                        minLength={6}
                                        maxLength={100}
                                        className="uk-input"
                                        value={model.oldPassword}
                                        onChange={handleChange}
                                        required />

                                    {errors?.OldPassword && errors.OldPassword.map((error, index) => (
                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                    ))}
                                </div>
                            </div>
                            <div className="uk-margin-small">
                                <label className="uk-form-label" htmlFor="newPassword">Nueva Contraseña:</label>
                                <div className="uk-form-controls">
                                    <input
                                        type="password"
                                        id="newPassword"
                                        name="newPassword"
                                        autoComplete="new-password"
                                        minLength={6}
                                        maxLength={100}
                                        className="uk-input"
                                        value={model.newPassword}
                                        onChange={handleChange}
                                        required />

                                    {errors?.NewPassword && errors.NewPassword.map((error, index) => (
                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                    ))}
                                </div>
                            </div>
                            <div className="uk-margin-small">
                                <label className="uk-form-label" htmlFor="confirmPassword">Confirmación:</label>
                                <div className="uk-form-controls">
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        autoComplete="new-password"
                                        minLength={6}
                                        maxLength={100}
                                        className="uk-input"
                                        value={model.confirmPassword}
                                        onChange={handleChange}
                                        required />

                                    {errors?.ConfirmPassword && errors.ConfirmPassword.map((error, index) => (
                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                    ))}
                                </div>
                            </div>
                        </fieldset>
                        <button type="submit" className="uk-button uk-button-primary" disabled={formSubmitted}>
                            {formSubmitted ? "Cambiando..." : "Cambiar"}
                        </button>
                    </form>
                </div>
            </div>
        </Content>
    );
}