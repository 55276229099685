import React from "react";
import Content from "../../components/content";
import Heading from "../../components/heading";
import ProductIndex from "../../components/productIndex";
import UsePageTitle from "../../components/usePageTitle";

/** Página para administrar los productos */
export default function ProductsManage() {
    UsePageTitle('Administración de productos');

    return (
        <Content>
            <Heading text="Administración de productos" createOption="/producto/nuevo" />

            <ProductIndex isFromManagePage={true} />
        </Content>
    );
}