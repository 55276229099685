import { mdiLockOpenVariantOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import UIkit from "uikit";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";
import { Constants } from "../../constants";
import { iStatus } from "../../interfaces/status";
import { iUserDetails } from "../../interfaces/user";
import { formatConjunction, formatDate, translateRoles } from "../../utils";

/** Página para mostrar los detalles de un usuario */
export default function UserDetails() {
    const { id } = useParams<{ id: string }>();
    const [user, setUser] = useState<iUserDetails | null>(null);
    const [userStatus, setUserStatus] = useState<string>('');

    const { state } = useLocation() as {
        state: { user: iUserDetails } | null;
    };

    useEffect(() => {
        if (state?.user) {
            setUser(state.user);
        } else {
            fetch(`/api/Admin/User/Details/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    setUser(data as iUserDetails);
                });
        }
    }, [id, state]);

    useEffect(() => {
        setUserStatus(user?.status || '');
    }, [user]);

    const handleUnlockUser = () => {
        UIkit.modal.confirm('<p>¿Estás seguro de que deseas desbloquear el usuario?</p>').then(function () {
            fetch(`/api/Admin/User/Unlock/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    const status = data as iStatus

                    status.messages?.forEach(mesagge => {
                        UIkit.notification({
                            message: `${mesagge}`,
                            status: status.success ? 'success' : 'danger',
                            pos: 'top-center'
                        });
                    });

                    // Oculta el boton cuando se desbloquea el usuario ya que despues de esta acción no se obtiene de nuevo los datos y se seguiría mostrando dicho boton.
                    if (status.success) {
                        setUserStatus(Constants.UserStatus.Active)
                    };
                });
        }, function () {
            // nothing to do...
        })
    }

    UsePageTitle('Detalles del usuario');

    return (
        <Content>
            <Heading text="Detalles del usuario" backOption={"/usuarios"} editOption={userStatus == Constants.UserStatus.Active ? `/usuarios/${id}/editar` : undefined} deleteOption={userStatus != Constants.UserStatus.Deleted ? `/api/Admin/User/Delete/${id}` : undefined} />

            <section className="uk-margin-large-bottom">
                <div className="uk-child-width-1-1 uk-child-width-1-2@m" data-uk-grid>
                    <div>
                        <div data-uk-margin>
                            <span className="uk-width-1-1 uk-width-1-3@s">
                                Nombre:
                            </span>
                            <span>
                                <strong>
                                    {user?.name}
                                </strong>
                            </span>
                        </div>
                        <div data-uk-margin>
                            <span className="uk-width-1-1 uk-width-1-3@s">
                                Apellidos:
                            </span>
                            <span>
                                <strong>
                                    {user?.lastName}
                                </strong>
                            </span>
                        </div>
                        <div data-uk-margin>
                            <span className="uk-width-1-1 uk-width-1-3@s">
                                E-Mail:
                            </span>
                            <span>
                                <strong>
                                    {user?.email}
                                </strong>
                            </span>
                        </div>

                        <hr />

                        <div data-uk-margin>
                            <span className="uk-width-1-1 uk-width-1-3@s">
                                Fecha de registro:
                            </span>
                            <span>
                                <strong>
                                    {formatDate(user?.createdTime)}
                                </strong>
                            </span>
                        </div>
                        <div data-uk-margin>
                            <span className="uk-width-1-1 uk-width-1-3@s">
                                Último ingreso:
                            </span>
                            <span>
                                <strong>
                                    {formatDate(user?.lastVisit)}
                                </strong>
                            </span>
                        </div>
                        <div data-uk-margin>
                            <span className="uk-width-1-1 uk-width-1-3@s">
                                Estatus:
                            </span>
                            <span>
                                <strong>
                                    {userStatus}
                                </strong>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div data-uk-margin>
                            <span className="uk-width-1-1 uk-width-1-3@s">
                                Roles:
                            </span>
                            <span>
                                <strong>
                                    {formatConjunction(translateRoles(user?.roles ?? []))}
                                </strong>
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            {userStatus == Constants.UserStatus.Blocked && <button
                className="uk-button uk-button-link uk-text-primary"
                onClick={handleUnlockUser}>
                <Icon path={mdiLockOpenVariantOutline} size={1} />
                <span className="uk-margin-small-left">
                    Desbloquear usuario
                </span>
            </button>}
        </Content>
    );
}