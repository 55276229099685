import React from "react";
import { useUser } from "../userContext";
import { Navigate, Outlet } from "react-router-dom";

/**
 * Interfaz con las propiedades del componente PrivateRoutes.
 */
export interface iPrivateRoutesProps {
    /** Roles con acceso */
    roles?: string[];
}

/**
 * Permite tener rutas privadas.
 * 
 * Redirige a la página index en caso de que el usuario no este logeado o no cuente con uno de los roles dados.
 * 
 * @returns
 */
export default function PrivateRoutes({ roles }: iPrivateRoutesProps) {
    const { user } = useUser();

    // Si el usuario no está logeado, redirige a la página de inicio.
    if (!user) {
        return <Navigate to="/" replace />;
    }

    // Si el usuario no cuenta con uno de los roles con acceso, redirige a la página de inicio.
    if (roles && roles.length > 0) {
        const hasRequiredRole = user.roles.some(userRole => roles.includes(userRole));

        if (!hasRequiredRole) return <Navigate to="/" replace />;
    }

    return <Outlet />;
}