import { mdiClose, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { iSizeGuide } from "../interfaces/sizeGuide";

/** Propiedades del componente InputSizeGuide. */
export interface iProps {
    /** Valor que tendrá por defecto el componente */
    defaultValue: iSizeGuide[];

    /** Función que se llama cada vez que la lista de elementos cambia. */
    onItemsChange: (value: iSizeGuide[]) => void;
}

/**
 * InputSizeGuide Component
 * 
 * Un componente de lista dinámica que permite a los usuarios agregar y eliminar elementos de una lista.
 * Los elementos se introducen en los campos correspondientes y, al presionar "Enter" o hacer clic en el botón,
 * se agregan a la lista. Los elementos existentes se muestran en una lista ordenada con la opción de eliminarlos.
*/
export default function InputSizeGuide({ defaultValue, onItemsChange }: iProps) {
    const [items, setItems] = useState<iSizeGuide[]>([]);

    const sizeRef = useRef<HTMLInputElement>(null);
    const cmRef = useRef<HTMLInputElement>(null);
    const euRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (defaultValue.length <= 0) return;

        setItems(defaultValue)
    }, [defaultValue]);

    useEffect(() => {
        onItemsChange(items)
    }, [items]);

    const addItem = () => {
        if (!sizeRef.current || !cmRef.current || !euRef.current) return;

        const sizeValue = Number(sizeRef.current.value);
        const cmValue = Number(cmRef.current.value);
        const euValue = Number(euRef.current.value);

        if (isNaN(sizeValue) || isNaN(cmValue) || isNaN(euValue)) return;

        if (!sizeValue) {
            sizeRef.current.required = true;
            return
        }

        if (!cmValue) {
            cmRef.current.required = true;
            return
        }

        if (!euValue) {
            euRef.current.required = true;
            return
        }

        const newValue: iSizeGuide = {
            size: sizeValue,
            cm: cmValue,
            eu: euValue,
        };

        setItems((prevItems) => [
            ...prevItems,
            newValue
        ]);

        sizeRef.current.value = '';
        cmRef.current.value = '';
        euRef.current.value = '';

        cmRef.current.required = false;
        sizeRef.current.required = false;
        euRef.current.required = false;

        sizeRef.current.focus();
    }

    const removeItem = (index: number) => {
        setItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addItem();
        }
    };

    return (
        <>
            <div className="input-dynamic-list">
                <div className="dynamic-list-panel">
                    {
                        items.length > 0 ? (
                            <ul>
                                {items.map((item, index) => (
                                    <li key={`item-${index}`}>
                                        <div>
                                            <div className="uk-grid-small" data-uk-grid>
                                                <div className="uk-width-expand">
                                                    <strong>{item.size}</strong>
                                                </div>

                                                <div className="uk-width-expand">
                                                    {item.cm}
                                                </div>

                                                <div className="uk-width-expand">
                                                    {item.eu}
                                                </div>
                                            </div>

                                            <button type="button" onClick={() => removeItem(index)}>
                                                <Icon path={mdiClose} size={1} />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="uk-text-muted uk-padding-small" onClick={() => sizeRef.current?.focus()}>
                                Escribe y presiona el botón <code>+</code> o la tecla <code>Enter</code> para agregar elementos en la lista
                            </div>
                        )
                    }
                </div>
                <div className="dynamic-list-input">
                    <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-expand">
                            <input
                                type="number"
                                min={0}
                                className="uk-input"
                                ref={sizeRef}
                                placeholder="Radon size"
                                onKeyDown={handleKeyPress} />
                        </div>

                        <div className="uk-width-expand">
                            <input
                                type="number"
                                min={0}
                                className="uk-input"
                                placeholder="CM"
                                ref={cmRef}
                                onKeyDown={handleKeyPress} />
                        </div>

                        <div className="uk-width-expand">
                            <input
                                type="number"
                                min={0}
                                className="uk-input"
                                placeholder="EU"
                                ref={euRef}
                                onKeyDown={handleKeyPress} />
                        </div>

                        <div className="uk-width-auto uk-margin-xsmall-left">
                            <button
                                type="button"
                                title="Agregar"
                                data-uk-tooltip=""
                                className="uk-button uk-button-secondary"
                                onClick={addItem}>
                                <Icon path={mdiPlus} size={1} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
