import React from "react";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";

/** Página que muestra los productos en el carrito del usuario */
export default function Cart() {
    UsePageTitle('Carrito de compra');

    return (
        <Content>
            <Heading text="Carrito de compra" />

            <div>
                <p>Contenido</p>
            </div>
        </Content>
    );
}