import React from "react";
import { Link } from "react-router-dom";
import { iCourseDetails } from "../interfaces/course";
import { useUser } from "../userContext";
import { formatConjunction, formatCurrencyMXN } from "../utils";

interface iCourseCardInfoProps {
    /** Modelo con los datos de una acedmia */
    course: iCourseDetails;

    /** Indica si el componente se esta viendo desde la administración */
    isFromManagePage?: boolean;
}

/** Pequeña card con información básica de un curso */
export default function CourseCardInfo({ course, isFromManagePage = false }: iCourseCardInfoProps) {
    const { user } = useUser();

    const img = course.media && course.media.length > 0 ? `/storage/courses/${course.media[0]}` : "/img/no-image.jpg";

    return (
        <Link to={`/clase/${course.id}`} state={{ isFromManagePage }}>
            <div className="uk-child-width-1-1 uk-child-width-1-3@s" data-uk-grid="">
                <div className="uk-width-auto@s">
                    <img height="100" width="100" src={img} />
                </div>
                <div className="uk-width-expand@s">
                    <div className="uk-margin-small">
                        <h5 className="uk-margin-remove">
                            {course.title}
                        </h5>
                        <div className="uk-text-italic">
                            <span className="uk-text-bold uk-text-primary">{formatCurrencyMXN(course.price ?? 0)}</span> {course.singlePayment ? 'pago único' : 'por clase'}
                        </div>
                    </div>
                    {course?.disciplines.length > 0 && <>
                        <div>
                            <strong>Diciplinas: </strong>
                            {formatConjunction(course?.disciplines)}
                        </div>
                    </>}
                    {course?.levels.length > 0 && <>
                        <div>
                            <strong>Niveles: </strong>
                            {formatConjunction(course?.levels ?? [])}
                        </div>
                    </>}
                    {/*{!isFromManagePage &&*/}
                    {/*    <div>*/}
                    {/*        <Link to={user != null ? `/clase/${course.id}/registro` : `/clase/${course.id}/identificacion`} state={{ course }} className="uk-button uk-button-link">*/}
                    {/*            Registrarme <Icon path={mdiArrowRight} size={1} />*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
                <div className="uk-width-auto@s">
                    <button type="button" className="uk-button uk-button-secondary uk-button-small">
                        Más Información
                    </button>
                </div>
            </div>
        </Link>
    );
}