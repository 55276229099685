import { mdiAlertOutline, mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";
import "cleave.js/dist/addons/cleave-phone.mx.js";
import Cleave from "cleave.js/react";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import UIkit from "uikit";
import Breadcrumb from "../../components/breadcrumb";
import Content from "../../components/content";
import Heading from "../../components/heading";
import SelectedCourseInfo from "../../components/selectedCourseInfo";
import UsePageTitle from "../../components/usePageTitle";
import { iBreadcrumbItem } from "../../interfaces/breadcrumb";
import { iCourseDetails } from "../../interfaces/course";
import { iParticipantForm } from "../../interfaces/participant";
import { iResponsibleForm } from "../../interfaces/responsible";
import { iFileStatus, iStatus } from "../../interfaces/status";
import { iSubscriptionRegister } from "../../interfaces/subscription";
import { iRegister } from "../../interfaces/user";
import { useUser } from "../../userContext";

/** Datos iniciales para el responsable */
const initialResponsibleData: iResponsibleForm = {
    name: '',
    phone: '',
    email: '',
    credentialFront: '',
    credentialBack: '',
    street: '',
    streetNumber: '',
    neighborhood: '',
    zipCode: '',
    city: '',
    state: ''
}

/** Datos iniciales para la lista de participantes */
const initialParticipantsData: iParticipantForm[] = [{
    name: '',
    birthdate: '',
    relationship: '',
    parentName: ''
}]

/** Página para registrarse en un curso */
export default function CourseRegister() {
    // El id se pone como string debido a que el valor es tomado de la url y esto siempre es string.
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const { user, updateUser } = useUser();

    const [course, setCourse] = useState<iCourseDetails | null>(null);

    const [responsible, setResponsible] = useState<iResponsibleForm>(initialResponsibleData);
    const [participants, setParticipants] = useState<iParticipantForm[]>(initialParticipantsData);

    const [numClasses, setNumClasses] = useState<number>(1);
    const [numParticipants, setNumParticipants] = useState<number>(1);
    const [acceptedConditions, setAcceptedConditions] = useState<boolean>(false);

    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

    const [minParticiantAge, setMinParticiantAge] = useState<string>('');
    const [maxParticiantAge, setMaxParticiantAge] = useState<string>('');

    const { state } = useLocation() as {
        state: { course: iCourseDetails, newUser: boolean } | null;
    };

    useEffect(() => {
        const currentDate = new Date();

        const minDate = new Date(currentDate.getFullYear() - 2, currentDate.getMonth(), currentDate.getDate());
        const maxDate = new Date(currentDate.getFullYear() - 60, currentDate.getMonth(), currentDate.getDate());

        setMinParticiantAge(minDate.toISOString().split('T')[0]);
        setMaxParticiantAge(maxDate.toISOString().split('T')[0]);
    }, []);

    /** LLena por defecto datos del responsable con los del usuario firmado */
    useEffect(() => {
        setResponsible(preResponsible => ({
            ...preResponsible,
            name: `${user?.name || ''} ${user?.lastName || ''}`.trim(),
            phone: user?.phone ?? '',
            email: user?.email ?? ''
        }));
    }, []);

    useEffect(() => {
        if (state?.course) {
            setCourse(state.course);
        } else {
            fetch(`/api/Course/Details/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    setCourse(data as iCourseDetails);
                });
        }
    }, [id, state]);

    //useEffect(() => {
    //    if (formSubmitted) {
    //        registerParticipants();
    //    }
    //}, [user])

    useEffect(() => {
        if (formSubmitted) {
            if (state?.newUser) {
                registerNewUser();
            } else {
                registerParticipants();
            }
        }
    }, [formSubmitted])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setFormSubmitted(true);

        //if (state?.newUser) {
        //    registerNewUser();
        //} else {
        //    registerParticipants();
        //}

        //setFormSubmitted(false);
    }

    /** 
     * Realiza un fetch a la api para registrar a los participantes y su responsable
     * El parametro "registerNewUser" indica si se esta registrando un nuevo usuario con los datos del responsable. Esto para poder iniciar la sesión del usuario una vez se registren los datos del responsable.
     */
    const registerParticipants = (registerNewUser: boolean = false) => {
        const subscriptionPrice = course?.singlePayment ? course.price : (course?.price ?? 0) * numClasses;
        const subscriptionAcquiredClasses = course?.singlePayment ? null : numClasses;

        const registrationData: iSubscriptionRegister = {
            courseId: !isNaN(Number(id)) ? Number(id) : 0,
            price: subscriptionPrice,
            acquiredClasses: subscriptionAcquiredClasses,
            responsible: responsible,
            participants: participants
        };

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(registrationData)
        };

        fetch(`/api/Me/RegisterParticipants?acceptedConditions=${acceptedConditions}`, requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                //setErrors({});

                if (status.success) {
                    if (registerNewUser) updateUser();

                    // NOTE: Se pasan datos a la siguiente página con state para evitar consultas a la DB.
                    navigate(`/suscripcion/${status.id}/informacion-de-pago`, { state: { course, newUser: state?.newUser || false } });
                } else {
                    if (status.errors) setErrors(data.errors);

                    status.messages?.forEach(mesagge => {
                        UIkit.notification({
                            message: `${mesagge}`,
                            status: status.success ? 'success' : 'danger',
                            pos: 'top-center'
                        });
                    });
                }
            }).finally(() => {
                setFormSubmitted(false);
            });
    }

    /** Realiza un fetch a la api para registrar el nuevo usuario */
    const registerNewUser = () => {
        const userData: iRegister = {
            name: responsible.name,
            email: responsible.email,
            password: 'NewUser.1', // Se manda una contraseña default para evitar errores con el modelo, pero esta contraseña es modificada en el back.
            confirmPassword: 'NewUser.1'
        }

        const requestUserOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData)
        };

        fetch(`/api/User/NewUser?conditionsAcceptation=${acceptedConditions}&newUser=${state?.newUser || false}`, requestUserOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                setErrors({});

                if (status.success) {
                    //updateUser();
                    registerParticipants(true);
                } else {
                    if (status.errors) setErrors(data.errors);

                    status.messages?.forEach(mesagge => {
                        UIkit.notification({
                            message: `${mesagge}`,
                            status: status.success ? 'success' : 'danger',
                            pos: 'top-center'
                        });
                    });
                }
            });
    }

    /** Función que actualiza los datos del responsable */
    const handleResponsibleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setResponsible(prevModel => ({
            ...prevModel,
            [name]: value
        }));
    };

    /** Función que maneja el cambio del componente InputPhone para el modelo del responsable */
    const handleResponsibleInputPhone = (name: string, value: string) => {
        setResponsible(prevModel => ({
            ...prevModel,
            [name]: value
        }));
    };

    /** Función que actualiza el número de clases que tomarán los participantes */
    const handleNumClassesChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        setNumClasses(value);
    };

    /** Función que actualiza el número de participantes a registrar */
    const handleNumParticipantsChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        setNumParticipants(value);

        // Genera los formularios de participantes según el número ingresado
        setParticipants(prevParticipants => {
            const newParticipants = [...prevParticipants];

            // Agrega los participantes faltantes
            for (let i = newParticipants.length; i < value; i++) {
                newParticipants.push({ name: '', birthdate: '', relationship: '', parentName: '' });
            }

            // Elimina los participantes sobrantes
            if (value < newParticipants.length) {
                newParticipants.splice(value);
            }

            return newParticipants;
        });
    };

    /** Función que actualiza los datos de cada participante */
    const handleParticipantChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setParticipants(prevParticipants => {
            const updatedParticipants = [...prevParticipants];
            updatedParticipants[index] = { ...updatedParticipants[index], [name]: value };

            return updatedParticipants;
        });
    };

    /** Indica si el participante es mayor de edad */
    const isParticipantAdult = (birthdate: string) => {
        if (!birthdate) return true;

        const participantDate = new Date(birthdate);
        const currentDate = new Date();

        const adultDate = new Date(participantDate);
        adultDate.setFullYear(adultDate.getFullYear() + 18);

        return currentDate >= adultDate;
    }

    /** Actualiza la credencial de elector */
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, files } = e.target;

        if (files && files.length > 0) {
            const formData = new FormData();
            formData.append('file', files[0]);

            const requestOptions: RequestInit = {
                method: 'POST',
                body: formData
            };

            fetch('/api/uploads', requestOptions)
                .then(res => res.json())
                .then(data => {
                    const status = data as iFileStatus;

                    if (status.success) {
                        setResponsible(prevModel => ({
                            ...prevModel,
                            [name]: status.filename
                        }));
                    } else {
                        UIkit.notification({
                            message: `${status.messages[0]}`,
                            status: 'danger',
                            pos: 'top-center'
                        });

                        setResponsible(prevModel => ({
                            ...prevModel,
                            [name]: ''
                        }));
                    }
                });
        }
    };

    const breadcrumbItems: iBreadcrumbItem[] = [
        { path: '/', text: 'Inicio' },
        { path: '/academia', text: 'Academia' },
        { path: `/clase/${id}`, text: `${course?.title}` },
        { path: `/clase/${id}/registro`, text: 'Registro' }
    ];

    UsePageTitle('Información de Registro');

    // TODO: si ya esta firmado, mostrar una lista de los participantes que estan registrados en la cuenta para no crear registros duplicados
    // TODO: si el usuario ya antes subió la credencial de elector, no solicitarla de nuevo a menos que sea otro el responsable

    return (
        <Content>
            <Heading text="Información de Registro" />
            <Breadcrumb items={breadcrumbItems} />
            <section>
                <div className="uk-child-width-1-1 uk-child-width-1-3@m" data-uk-grid>
                    <div className="uk-width-2-3@m">
                        <form onSubmit={handleSubmit} className="uk-form-stacked">
                            <fieldset className="uk-fieldset" disabled={formSubmitted}>
                                <legend className="uk-legend">
                                    Responsable
                                </legend>
                                <div className="uk-alert-warning" data-uk-alert>
                                    <div className="uk-grid-small" data-uk-grid>
                                        <div className="uk-width-auto">
                                            <span>
                                                <Icon path={mdiAlertOutline} size={1} />
                                            </span>
                                        </div>
                                        <div className="uk-width-expand">
                                            <span>
                                                <strong>Importante:</strong> Para finalizar el proceso de inscripción y antes de tomar las clases deberás imprimir, firmar y entregar las responsivas correspondientes a tu entrenador.
                                                Puedes ver un ejemplo de este documento <a className="uk-link-text" href="/carta-responsiva" target="_blank">presionando aquí <Icon path={mdiOpenInNew} size={.75} /></a>.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-form-horizontal">
                                    <div className="uk-margin-small">
                                        <label className="uk-form-label" htmlFor="name">Tu nombre completo:</label>
                                        <div className="uk-form-controls">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                autoComplete="name"
                                                value={responsible?.name}
                                                className="uk-input"
                                                onChange={handleResponsibleChange}
                                                required />

                                            {errors["Responsible.Name"] && errors["Responsible.Name"].map((error, index) => (
                                                <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="uk-margin-small">
                                        <label className="uk-form-label" htmlFor="phone">Tu teléfono:</label>
                                        <div className="uk-form-controls">
                                            <Cleave
                                                id="phone"
                                                name="phone"
                                                value={responsible.phone}
                                                required
                                                autoComplete="tel"
                                                className="uk-input"
                                                options={{
                                                    phone: true,
                                                    phoneRegionCode: 'MX'
                                                }}
                                                onChange={(e: ChangeEvent) => handleResponsibleInputPhone("phone", (e.target as any).rawValue)}
                                            />

                                            {errors["Responsible.Phone"] && errors["Responsible.Phone"].map((error, index) => (
                                                <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="uk-margin-small">
                                        <label className="uk-form-label" htmlFor="email">Tu E-Mail:</label>
                                        <div className="uk-form-controls">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                autoComplete="email"
                                                value={responsible?.email}
                                                className="uk-input"
                                                onChange={handleResponsibleChange}
                                                required />

                                            {errors["Responsible.Email"] && errors["Responsible.Email"].map((error, index) => (
                                                <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="uk-margin-small">
                                        <label className="uk-form-label" htmlFor="street">Dirección:</label>
                                        <div className="uk-form-controls">
                                            <div className="uk-grid-small" data-uk-grid>
                                                <div className="uk-width-1-1 uk-width-1-2@s">
                                                    <input
                                                        type="text"
                                                        id="street"
                                                        name="street"
                                                        max="255"
                                                        value={responsible?.street}
                                                        autoComplete="shipping address-level1"
                                                        className="uk-input"
                                                        onChange={handleResponsibleChange}
                                                        placeholder="Calle y Número Exterior"
                                                        required />

                                                    {errors["Responsible.Street"] && errors["Responsible.Street"].map((error, index) => (
                                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                                    ))}
                                                </div>
                                                <div className="uk-width-1-1 uk-width-1-2@s">
                                                    <input
                                                        type="text"
                                                        id="streetNumber"
                                                        name="streetNumber"
                                                        max="20"
                                                        value={responsible?.streetNumber ?? ''}
                                                        autoComplete="shipping address-level2"
                                                        className="uk-input"
                                                        onChange={handleResponsibleChange}
                                                        placeholder="Número Interior (si aplica)"
                                                    />

                                                    {errors["Responsible.StreetNumber"] && errors["Responsible.StreetNumber"].map((error, index) => (
                                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-margin-small">
                                        <label className="uk-form-label" htmlFor="neighborhood">Colonia:</label>
                                        <div className="uk-form-controls">
                                            <input
                                                type="text"
                                                id="neighborhood"
                                                name="neighborhood"
                                                value={responsible?.neighborhood}
                                                max="255"
                                                autoComplete="shipping address-level3"
                                                className="uk-input"
                                                onChange={handleResponsibleChange}
                                                required />

                                            {errors["Responsible.Neighborhood"] && errors["Responsible.Neighborhood"].map((error, index) => (
                                                <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="uk-margin-small">
                                        <label className="uk-form-label" htmlFor="zipCode">Código Postal:</label>
                                        <div className="uk-form-controls">
                                            <Cleave
                                                id="zipCode"
                                                name="zipCode"
                                                value={responsible.zipCode}
                                                required
                                                autoComplete="shipping postal-code"
                                                className="uk-input"
                                                options={{
                                                    blocks: [5],
                                                    numericOnly: true,
                                                    numeralPositiveOnly: true
                                                }}
                                                onChange={(e: ChangeEvent) => setResponsible(prevModel => ({ ...prevModel, zipCode: (e.target as any).rawValue }))}
                                            />

                                            {errors["Responsible.ZipCode"] && errors["Responsible.ZipCode"].map((error, index) => (
                                                <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="uk-margin-small">
                                        <label className="uk-form-label" htmlFor="city">Ciudad:</label>
                                        <div className="uk-form-controls">
                                            <input
                                                type="text"
                                                id="city"
                                                name="city"
                                                max="255"
                                                value={responsible?.city}
                                                autoComplete="shipping address-level2"
                                                className="uk-input"
                                                onChange={handleResponsibleChange}
                                                required />

                                            {errors["Responsible.City"] && errors["Responsible.City"].map((error, index) => (
                                                <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="uk-margin-small">
                                        <label className="uk-form-label" htmlFor="state">Estado:</label>
                                        <div className="uk-form-controls">
                                            <input
                                                type="text"
                                                id="state"
                                                name="state"
                                                max="255"
                                                value={responsible?.state}
                                                autoComplete="shipping address-level1"
                                                className="uk-input"
                                                onChange={handleResponsibleChange}
                                                required />

                                            {errors["Responsible.State"] && errors["Responsible.State"].map((error, index) => (
                                                <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="uk-margin-small">
                                        <label className="uk-form-label" htmlFor="credentialFront">Credencial de elector:</label>
                                        <div className="uk-form-controls">
                                            <div className="uk-grid-small" data-uk-grid>
                                                <div className="uk-width-1-1 uk-width-1-2@s">
                                                    <div data-uk-form-custom="target: true">
                                                        <input
                                                            type="file"
                                                            id="credentialFront"
                                                            name="credentialFront"
                                                            accept="image/*"
                                                            onChange={handleFileChange}
                                                            aria-label="Anverso de la credencial de elector"
                                                            required />
                                                        <input className="uk-input uk-form-width-large" type="text" placeholder="Anverso" aria-label="Anverso de la credencial de elector" />
                                                    </div>

                                                    {errors["Responsible.CredentialFront"] && errors["Responsible.CredentialFront"].map((error, index) => (
                                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                                    ))}
                                                </div>
                                                <div className="uk-width-1-1 uk-width-1-2@s">
                                                    <div data-uk-form-custom="target: true">
                                                        <input
                                                            type="file"
                                                            id="credentialBack"
                                                            name="credentialBack"
                                                            accept="image/*"
                                                            onChange={handleFileChange}
                                                            aria-label="Reverso de la credencial de elector"
                                                            required />
                                                        <input className="uk-input uk-form-width-large" type="text" placeholder="Reverso" aria-label="Reverso de la credencial de elector" />
                                                    </div>

                                                    {errors["Responsible.CredentialBack"] && errors["Responsible.CredentialBack"].map((error, index) => (
                                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            {!course?.singlePayment && <div className="uk-section-xsmall">
                                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                                    <legend className="uk-legend">
                                        Clases
                                    </legend>
                                    <label className="uk-form-label" htmlFor="classNumber">
                                        ¿Cuantas clases te gustaría contratar?
                                    </label>
                                    <div className="uk-margin-small-bottom">
                                        <input
                                            type="number"
                                            id="classNumber"
                                            min={1}
                                            max={course?.classTo ?? 1}
                                            value={numClasses}
                                            className="uk-input uk-form-width-small"
                                            onChange={handleNumClassesChange}
                                            required />
                                    </div>
                                    <span className="uk-text-meta">
                                        Las clases pueden ser divididas entre el total de alumnos que registres a continuación
                                    </span>
                                </fieldset>
                            </div>}


                            <div className="uk-section-xsmall">
                                <fieldset className="uk-fieldset" disabled={formSubmitted}>
                                    <legend className="uk-legend">
                                        Alumnos
                                    </legend>
                                    <label className="uk-form-label" htmlFor="numParticipants">
                                        ¿Cuantas personas deseas registrar para tomar las clases?
                                    </label>
                                    <input
                                        type="number"
                                        id="numParticipants"
                                        min={1}
                                        max={10}
                                        value={numParticipants}
                                        className="uk-input uk-form-width-small"
                                        onChange={handleNumParticipantsChange}
                                        required />
                                </fieldset>
                            </div>

                            <div className="uk-section-xsmall">
                                {participants.map((participant, index) => (
                                    <fieldset key={index} className="uk-fieldset" disabled={formSubmitted}>
                                        <p className="uk-h4">
                                            Alumno {index + 1}
                                        </p>
                                        <div className="uk-form-horizontal">
                                            <div className="uk-margin-small">
                                                <label className="uk-form-label" htmlFor={`name${index}`}>Nombre completo:</label>
                                                <div className="uk-form-controls">
                                                    <input
                                                        type="text"
                                                        id={`name${index}`}
                                                        name="name"
                                                        maxLength={255}
                                                        value={participant.name}
                                                        className="uk-input"
                                                        onChange={(e) => handleParticipantChange(index, e)}
                                                        required />

                                                    {errors[`Participants[${index}].Name`] && errors[`Participants[${index}].Name`].map((error, index) => (
                                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="uk-margin-small">
                                                <label className="uk-form-label" htmlFor={`birthdate${index}`}>Fecha de nacimiento:</label>
                                                <div className="uk-form-controls">
                                                    <input
                                                        type="date"
                                                        id={`birthdate${index}`}
                                                        name="birthdate"
                                                        min={maxParticiantAge}
                                                        max={minParticiantAge}
                                                        value={participant.birthdate}
                                                        className="uk-input"
                                                        onChange={(e) => handleParticipantChange(index, e)}
                                                        required />

                                                    {errors[`Participants[${index}].Birthdate`] && errors[`Participants[${index}].Birthdate`].map((error, index) => (
                                                        <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                                    ))}
                                                </div>
                                            </div>
                                            {!isParticipantAdult(participant.birthdate) && <>
                                                <div className="uk-margin-small">
                                                    <label className="uk-form-label" htmlFor={`relationship${index}`}>Parentesco:</label>
                                                    <div className="uk-form-controls">
                                                        <input
                                                            type="text"
                                                            id={`relationship${index}`}
                                                            name="relationship"
                                                            maxLength={255}
                                                            value={participant.relationship}
                                                            className="uk-input"
                                                            onChange={(e) => handleParticipantChange(index, e)}
                                                            required={!isParticipantAdult(participant.birthdate)}
                                                        />

                                                        {errors[`Participants[${index}].Relationship`] && errors[`Participants[${index}].Relationship`].map((error, index) => (
                                                            <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="uk-margin-small">
                                                    <label className="uk-form-label" htmlFor={`parentName${index}`}>Nombre del Padre, Madre o Tutor:</label>
                                                    <div className="uk-form-controls">
                                                        <input
                                                            type="text"
                                                            id={`parentName${index}`}
                                                            name="parentName"
                                                            maxLength={255}
                                                            value={participant.parentName}
                                                            className="uk-input"
                                                            onChange={(e) => handleParticipantChange(index, e)}
                                                            required={!isParticipantAdult(participant.birthdate)}
                                                        />

                                                        {errors[`Participants[${index}].ParentName`] && errors[`Participants[${index}].ParentName`].map((error, index) => (
                                                            <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>}

                                        </div>
                                    </fieldset>
                                ))}
                            </div>

                            <p>
                                <label>
                                    <input
                                        type="checkbox"
                                        className="uk-checkbox"
                                        id="conditionsAcceptation"
                                        checked={acceptedConditions}
                                        onChange={(e) => setAcceptedConditions(e.target.checked)}
                                        required />
                                    &nbsp;
                                    Acepto que he leído y estoy de acuerdo con los <Link to="/terminos-y-condiciones" target="_blank">términos y condiciones de uso <Icon path={mdiOpenInNew} size={.75} /></Link> y la <Link to="/politica-de-privacidad" target="_blank">politica de privacidad <Icon path={mdiOpenInNew} size={.75} /></Link>
                                </label>
                            </p>

                            <button type="submit" className="uk-button uk-button-primary uk-margin-medium-top" disabled={formSubmitted}>
                                Continuar
                            </button>
                        </form>
                    </div>
                    <div>
                        {course != null && <SelectedCourseInfo course={course} btnSelectAnotherCourse={true} />}
                    </div>
                </div>
            </section>
        </Content>
    );
}