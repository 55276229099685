import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UIkit from "uikit";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";
import UserForm from "../../components/userForm";
import { iStatus } from "../../interfaces/status";
import { iUserForm } from "../../interfaces/user";

/** Página para editar un usuario  */
export default function UserEdit() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

    const handleSubmit = (userData: iUserForm) => {
        setErrors({});

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData)
        };

        setFormSubmitted(true);

        fetch(`/api/Admin/User/Edit/${id}`, requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                if (status.success) navigate(`/usuarios/${id}`);

                status.messages?.forEach(mesagge => {
                    UIkit.notification({
                        message: `${mesagge}`,
                        status: status.success ? 'success' : 'danger',
                        pos: 'top-center'
                    });
                });

                if (status.errors) setErrors(data.errors);
            })
            .finally(() => {
                setFormSubmitted(false);
            });
    }

    UsePageTitle('Editar usuario');

    return (
        <Content>
            <Heading text="Editar usuario" backOption={-1} />

            <section className="uk-margin-large-bottom">
                <UserForm id={id}
                    errors={errors}
                    onSubmit={handleSubmit}
                    formSubmitted={formSubmitted}
                />
            </section>
        </Content>
    );
}