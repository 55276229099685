import React, { useEffect, useState } from "react";
import UIkit from "uikit";
import Content from "../components/content";
import Heading from "../components/heading";
import SectionCard, { iSectionCardItem } from "../components/sectionCard";
import TomSelect from "../components/tomSelect";
import UsePageTitle from "../components/usePageTitle";
import { ADMIN, CLASSCAPTURIST, GENERALUSER, INSTRUCTOR, PRODUCTCAPTURIST } from "../constants";
import { iParticipantClassData } from "../interfaces/participant";
import { iStatus } from "../interfaces/status";
import { useUser } from "../userContext";

/** Página que muestra el panel principal del usuario */
export default function Dashboard() {
    const { user } = useUser();

    // Indica si se esta impartiendo una clase.
    const [inClass, setInClass] = useState(false);
    // Indica si se esta realizando una busqueda de estudiantes.
    const [isLoading, setIsLoading] = useState(false);
    // Lista de estudiantes a mostrar.
    const [studentIds, setStudentIds] = useState<number[]>([]);
    // Lista de estudiantes seleccionados para obtener su información.
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
    // Lista de estudiantes chequeados para tomar la clase.
    const [checkedStudents, setCheckedStudents] = useState<number[]>([]);
    // Información de cada estudiante buscado. Una vez se inica la clase, se filtra solo por los alumnos chequeados para tomar la clase.
    const [studentData, setStudentData] = useState<iParticipantClassData[]>([]);

    useEffect(() => {
        if (!inClass && user?.roles.includes(INSTRUCTOR)) {
            fetch('/api/Instructor/GetParticipantIds')
                .then(res => res.json())
                .then(data => {
                    setStudentIds(data as number[]);
                })
        }
    }, [inClass, user])

    useEffect(() => {
        if (user?.roles.includes(INSTRUCTOR)) {
            fetch('/api/Instructor/InstructorInClass')
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    // NOTE: Si un instructor esta en clase se retornan los ids de los alumnos de su clase para obtener sus datos.
                    const studentIds = data as number[];

                    if (studentIds.length > 0) {
                        setInClass(true);
                        handleSearchStudents(studentIds);
                    }
                })
        }
    }, [user])

    const handleSearchStudents = (studentIds: number[]) => {
        if (studentIds.length <= 0) {
            UIkit.notification({
                message: 'No has ingresado estudiantes',
                status: 'danger',
                pos: 'top-center'
            });

            return;
        }

        setIsLoading(true);
        setCheckedStudents(preModel =>
            preModel.filter(student => selectedStudents.includes(student))
        );

        const query = studentIds.map(id => `ids=${id}`).join('&');

        fetch(`/api/Instructor/GetSearchParticipantsData?${query}`)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                setStudentData(data as iParticipantClassData[])
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleStudentSelectChange = (value: string[]) => {
        setSelectedStudents(value.map(v => Number(v)).filter(v => !isNaN(v)));
    }

    const handleStudentCheckedChange = (id: number) => {
        setCheckedStudents(prevData =>
            prevData.includes(id) ? prevData.filter(item => item !== id) : [...prevData, id]
        );
    }

    const handleStartClass = () => {
        if (checkedStudents.length <= 0) {
            UIkit.notification({
                message: 'No has selecionado estudiantes para tomar la clase',
                status: 'danger',
                pos: 'top-center'
            });

            return;
        }

        if (studentData.some(student => checkedStudents.includes(student.id) && student.status !== "Al Corriente")) {
            UIkit.notification({
                message: 'Uno o más de los estudiantes seleccionados no cumplen con los requerimentos para tomar la clase',
                status: 'danger',
                pos: 'top-center'
            });

            return;
        }

        const model = {
            ParticipantIds: checkedStudents,
            InstructorId: user?.id
        }

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model)
        };

        fetch('/api/Instructor/StartClass', requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus;

                if (status.success) {
                    setStudentData(prevData =>
                        prevData.filter(item => checkedStudents.includes(item.id))
                    );
                    setInClass(true);
                    setSelectedStudents([]);
                    setCheckedStudents([]);
                } else {
                    status.messages?.forEach(mesagge => {
                        UIkit.notification({
                            message: `${mesagge}`,
                            status: status.success ? 'success' : 'danger',
                            pos: 'top-center'
                        });
                    });
                }
            });
    }

    const handleEndClass = () => {
        const model = {
            ParticipantIds: studentData.map(m => m.id),
            InstructorId: user?.id
        }

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model)
        };

        fetch('/api/Instructor/EndClass', requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus;

                if (status.success) {
                    setInClass(false);
                    setStudentData([]);

                    UIkit.notification({
                        message: 'Clase finalizada con éxito',
                        status: 'success',
                        pos: 'top-center'
                    });
                }
            });
    }

    /** Obtiene la lista de SectionCard's a mostrar según el rol del usuario. */
    const getSectionItems = () => {
        let sectionItems: iSectionCardItem[] = [];

        if (user?.roles.includes(GENERALUSER)) {
            const items: iSectionCardItem[] = [
                {
                    icon: 'mdiSchoolOutline',
                    header: 'Mis clases',
                    description: 'Conoce todos los cursos a los que te haz inscrito y da seguimiento de tus clases.',
                    link: '/suscripciones'
                },
                //{
                //    icon: 'mdiCart',
                //    header: 'Mis Pedidos',
                //    description: 'Conoce todos los pedidos que haz realizado y da seguimiento de cada producto.',
                //    link: ``
                //}
            ];

            sectionItems.push(...items);
        }

        if (user?.roles.includes(CLASSCAPTURIST)) {
            const items: iSectionCardItem[] = [
                {
                    icon: 'mdiSkateboarding',
                    header: 'Administrar clases',
                    description: 'Da seguimiento a las personas con clases activas y actualiza o renueva sus suscripciones.',
                    link: '/administrar-clases'
                },
                {
                    icon: 'mdiSchoolOutline',
                    header: 'Administrar Cursos',
                    description: 'Crea nuevos cursos, actualiza los existentes o elimínalos para que no se muestren en el sitio web.',
                    link: '/administrar-cursos'
                },
                {
                    icon: 'mdiAccountCircleOutline',
                    header: 'Administrar suscripciones',
                    description: 'Da seguimiento a las personas con suscripciones activas y conoce las fechas de vigencia.',
                    link: '/administrar-suscripciones'
                },
            ];

            sectionItems.push(...items);
        }

        if (user?.roles.includes(PRODUCTCAPTURIST)) {
            const items: iSectionCardItem[] = [
                //{
                //    icon: 'mdiCart',
                //    header: 'Administrar Pedidos',
                //    description: 'Da seguimiento a los pedidos en línea de productos de la tienda y cambia el estatus.',
                //    link: ``
                //},
                {
                    icon: 'mdiRollerblade',
                    header: 'Administrar Productos',
                    description: 'Agrega nuevos productos, actualiza los existentes o elimínalos para que no se muestren en el sitio web.',
                    link: '/administrar-productos'
                }
            ];

            sectionItems.push(...items);
        }

        if (user?.roles.includes(ADMIN)) {
            const items: iSectionCardItem[] = [
                {
                    icon: 'mdiAccountCircle',
                    header: 'Administrar usuarios',
                    description: 'Otorga o quita el acceso al sistema, actualiza datos o permisos a los usuarios existentes.',
                    link: '/usuarios'
                },
                //{
                //    icon: 'mdiChartTimelineVariantShimmer',
                //    header: 'Reportes',
                //    description: 'Conoce el rendimiento de los usuarios y la plataforma a lo largo del tiempo',
                //    link: ``
                //}
            ];

            sectionItems.push(...items);
        }

        return sectionItems;
    }

    const sectionItems = getSectionItems();

    UsePageTitle('Lynx Skate House');

    // TODO: Mostrar información según el rol del usuario.
    return (
        <Content>
            <Heading text={`Hola ${user?.name}`} />

            <div className="uk-grid-match uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                {sectionItems.map(item => (
                    <div key={item.header}>
                        <SectionCard item={item} />
                    </div>
                ))}
            </div>

            {user?.roles.includes(INSTRUCTOR) && <>
                <section className="uk-section">
                    {!inClass
                        ?
                        <>
                            <h2>
                                Encuentra tus alumnos
                            </h2>

                            <div className="uk-grid-small" data-uk-grid>
                                <div className="uk-width-expand">
                                    <TomSelect
                                        id="studentIds"
                                        name="studentIds"
                                        options={studentIds.map(student => {
                                            return {
                                                text: student.toString(),
                                                value: student.toString()
                                            }
                                        })}
                                        items={selectedStudents.map(studentId => studentId.toString())}
                                        onValueChange={(value) => handleStudentSelectChange(value)}
                                        multiple
                                    />
                                </div>
                                <div className="uk-width-auto">
                                    <button onClick={() => handleSearchStudents(selectedStudents)} className="uk-button uk-button-secondary" disabled={isLoading}>
                                        {isLoading ? 'Buscando...' : 'Buscar'}
                                    </button>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="uk-flex uk-flex-middle uk-flex-between">
                                <h2 className="uk-margin-remove">
                                    En Clase...
                                </h2>
                                <button onClick={handleEndClass} className="uk-button uk-button-primary">
                                    Finalizar
                                </button>
                            </div>
                        </>
                    }

                    {studentData && studentData.length > 0 && <>
                        <div className="uk-margin">
                            <div className="uk-card uk-card-small uk-card-default uk-flex uk-flex-column">
                                <ul className="uk-list uk-list-divider uk-margin-remove">
                                    {studentData.map(student => (
                                        <li key={student.id} className="uk-padding-small uk-margin-remove">
                                            <div className="uk-flex">
                                                {!inClass && <>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            className="uk-checkbox"
                                                            onChange={() => handleStudentCheckedChange(student.id)}
                                                            checked={checkedStudents.includes(student.id)}
                                                        />
                                                    </div>
                                                </>}
                                                <div className="uk-flex-1 uk-margin-small-left">
                                                    <div className="uk-link-heading uk-flex" data-uk-margin>
                                                        <h2 className="uk-h6 uk-margin-remove-vertical">
                                                            <span className="uk-margin-small-right">
                                                                {student.id}
                                                            </span>
                                                            {student.name}
                                                        </h2>
                                                    </div>

                                                    <ul className="uk-subnav uk-margin-xsmall-vertical" data-uk-margin>
                                                        <li>
                                                            <div>
                                                                {student.status}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                {student.statusText}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div>
                                                                Suscripción: #{student.subscription}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {!inClass && <>
                            <div className="uk-margin">
                                <button onClick={handleStartClass} className="uk-button uk-button-primary">
                                    Iniciar la Clase
                                </button>
                            </div>
                        </>}
                    </>}

                </section>
            </>}
        </Content>
    );
}