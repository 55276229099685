import { UserRoleDictionary } from "./dictionaries";

/**
 * Función que permite formatear un número a moneda mexicana
 * @param amount
 * @returns
 */
export const formatCurrencyMXN = (amount: number): string => {
    const formattedAmount = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
    }).format(amount);

    return `${formattedAmount} MXN`;
};

/**
 * Función que permite formatear una fecha
 * 
 * Ejemplo: 18 de Julio de 2024, 02:45 pm
 * @param dateTimeString Fecha y hora a formatear en formato string
 * @param includeTime Indica si se quiere mostrar la hora
 * @returns
 */
export const formatDate = (dateTimeString?: string, includeTime: boolean = false): string => {
    if (!dateTimeString) return '';

    const [datePart, timePart] = dateTimeString.split('T');
    //const [datePart, timePart] = dateTimeString.split(' ');

    const [year, month, day] = datePart.split('-').map(Number);

    const [hour = 0, minute = 0] = timePart ? timePart.split(':').map(Number) : [0, 0];

    // Se resta 1 al mes porque en JS los meses empiezan en 0.
    const date = new Date(year, month - 1, day, hour, minute);

    if (isNaN(date.getTime())) return '';

    const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "long",
        year: "numeric",
    };

    if (includeTime) {
        options.hour = "2-digit";
        options.minute = "2-digit";
        options.hour12 = true;
    }

    return new Intl.DateTimeFormat('es-ES', options).format(date);
};

/**
 * Formatea un array de string en un texto separado con comas y un "y" antes del ultimo elemento.
 * 
 * @returns
 */
export const formatConjunction = (items: string[]) => {
    const length = items.length;

    if (length === 0) return '';

    if (length === 1) return items[0];

    // NOTE: No se utiliza Intl.ListFormat. debido a que no esta soportado. Cambiar versión de typescript???
    //const listFormatter = new Intl.ListFormat('es', { style: 'long', type: 'conjunction' });
    //return listFormatter.format(items);

    const allButLast = items.slice(0, -1).join(', ');
    const last = items[length - 1];

    return `${allButLast} y ${last}`;
}

/**
 * Obtiene un texto con la fecha actual en el formato YYYY-MM-DD HH:MM
 * 
 * Ejemplo: 2024-09-13 10:55
 * @returns
 */
export const getCurrentDateTime = (): string => {
    const now = new Date();

    // Obtener la fecha en formato YYYY-MM-DD
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 a 11
    const day = now.getDate().toString().padStart(2, '0');

    const dateString = `${year}-${month}-${day}`;

    // Obtener la hora en formato HH:MM:SS
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');

    const timeString = `${hours}:${minutes}`;

    return `${dateString}T${timeString}`;
}

/**
 * Muestra un texto con el rango de páginación.
 * 
 * Ejemplo: Monstando 1-10 de 45 resultados
 * 
 * @param currentPage página actual
 * @param itemsPerPage registros por página
 * @param totalItems registros totales
 * @returns
 */
export const getPaginationRangeText = (currentPage?: number, itemsPerPage?: number, totalItems?: number) => {
    if (currentPage === undefined || itemsPerPage === undefined || totalItems === undefined) return '';

    if (totalItems == 0) return '0 resultados';

    let lastItemNumber = currentPage * itemsPerPage;
    const firstItemNumber = lastItemNumber - itemsPerPage + 1;

    if (lastItemNumber > totalItems) lastItemNumber = totalItems;

    return `Monstando ${firstItemNumber}-${lastItemNumber} de ${totalItems} resultados`;
}

/**
 * Traduce los roles del usuario.
 * 
 * @param roles lista de roles sin traducir
 * @returns
 */
export const translateRoles = (roles: string[]): string[] => {
    return roles.map(role => UserRoleDictionary[role] || role);
};