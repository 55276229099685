import React from "react";
import { Constants } from "../constants";

/** 
 * Componente que muestra un label con el estatus de un usuario en el color correspondiente.
 * 
 * Verde para Activo.
 * Amarillo para Bloqueado.
 * Naranja para Suspendido / Bloqueado por el sistema.
 * Rojo para Eliminado.
 * 
 * @param status Estatus de la suscripción.
 * @returns
 */
export default function UserStatusLabel({ status }: { status: string }) {
    const getLabelColor = () => {
        switch (status) {
            case Constants.UserStatus.Active:
                return 'uk-label-success';
            case Constants.UserStatus.Blocked:
                return 'uk-label-warning';
            case Constants.UserStatus.Suspended:
                return 'uk-label-secondary';
            case Constants.UserStatus.Deleted:
                return 'uk-label-danger';
            default:
                return '';
        }
    }

    return (
        <>
            {status && <span className={`uk-label ${getLabelColor()}`}>
                {status}
            </span>}
        </>
    );
}