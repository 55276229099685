import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { iCourseIndex } from "../interfaces/course";
import { iPagination } from "../interfaces/pagination";
import { getPaginationRangeText } from "../utils";
import CourseCardInfo from "./courseCardInfo";
import Pagination from "./pagination";

interface iCourseIndexProps {
    /** Indica si el componente se esta viendo desde la administración */
    isFromManagePage?: boolean;
}

//const initialFiltersData: iCourseFilters = {
//    title: '',
//    benefits: ''
//}

/** Index de los cursos */
export default function CourseIndex({ isFromManagePage = false }: iCourseIndexProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState<iCourseIndex | null>(null);
    const [query, setQuery] = useState<string>('');
    //const [filters, setFilters] = useState<iCourseFilters>(initialFiltersData);
    const [pagination, setPagination] = useState<iPagination | null>(null);
    //const [levels, setLevels] = useState<iSelectOption[]>([]);
    //const [disciplines, setDisciplines] = useState<iSelectOption[]>([]);

    //useEffect(() => {
    //    fetch('/api/Level/GetLevels')
    //        .then(res => res.json())
    //        .then(data => {
    //            setLevels(data as iSelectOption[]);
    //        });

    //    fetch('/api/Discipline/GetDisciplines')
    //        .then(res => res.json())
    //        .then(data => {
    //            setDisciplines(data as iSelectOption[]);
    //        });
    //}, []);

    useEffect(() => {
        const paginationParams = new URLSearchParams(Object.entries(pagination ?? {}))

        const paginationQueryString = paginationParams.toString();

        let params = '';

        if (paginationQueryString) {
            if (params) {
                params += `&${paginationQueryString}`;
            } else {
                params += paginationQueryString;
            }
        }

        if (location.search != params) {
            setQuery(params);
            navigate({ search: params });
        }
    }, [pagination]);

    useEffect(() => {
        fetch(`/api/Course/GetCourseIndex?${query}`)
            .then(res => res.json())
            .then(data => {
                setData(data as iCourseIndex);
            });
    }, [query]);

    useEffect(() => {
        const search = location.search.slice(1);

        if (search != query) {
            setQuery(search);
        }
    }, [location.search])

    //const handleInputFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //    const { name, value } = e.target;

    //    setFilters({
    //        ...filters,
    //        [name]: value
    //    });
    //};

    //const handleMultipleSelectFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //    const { name, selectedOptions } = e.target;
    //    const selectedValues = Array.from(selectedOptions, (option) => option.value);

    //    setFilters({
    //        ...filters,
    //        [name]: selectedValues,
    //    });
    //};

    const handlePageChange = (page: number) => {
        setPagination({
            ...pagination,
            currentPage: page
        });
    };

    return (
        <>
            <div className="uk-margin">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                    <div>
                        {getPaginationRangeText(data?.pagination?.currentPage, data?.pagination?.itemsPerPage, data?.totalItems)}
                    </div>
                    {/*<div className="uk-grid uk-grid-small">*/}
                    {/*    <select className="uk-select uk-form-width-medium" aria-label="Seleccionar la opción de ordenamiento">*/}
                    {/*        <option>1</option>*/}
                    {/*        <option>2</option>*/}
                    {/*        <option>3</option>*/}
                    {/*    </select>*/}
                    {/*    <div>*/}
                    {/*        <button className="uk-button uk-button-primary">*/}
                    {/*            <Icon path={mdiFilterVariant} size={1} /> Filtros*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className="uk-margin">
                <div className="uk-child-width-1-1" data-uk-grid>
                    {data && data.list && data.list?.length > 0 ? (
                        data.list.map(course => (
                            <div key={course.id}>
                                <CourseCardInfo course={course} isFromManagePage={isFromManagePage} />
                            </div>
                        ))
                    ) : (
                        <p>No hay clases disponibles.</p>
                    )}
                </div>
            </div>

            {data && data.totalItems && data.totalPages && data.totalItems > 0 && data.totalPages > 1 && <div className="uk-section uk-section-small">
                <Pagination
                    currentPage={data.pagination.currentPage ?? 1}
                    totalPages={data.totalPages}
                    onPageChange={handlePageChange}
                />
            </div>}
        </>
    );
}