import React from "react";
import Content from "../components/content";
import Heading from "../components/heading";
import UsePageTitle from "../components/usePageTitle";

/** Página para contactar con Lynx */
export default function Contact() {
    UsePageTitle('Contacto');

    return (
        <Content>
            <Heading text="Contacto" />
            <section>
                <p>Texto</p>
            </section>
        </Content>
    );
}