import { mdiAccountCircle, mdiAccountPlus } from "@mdi/js";
import { Icon } from "@mdi/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import Content from "../../components/content";
import Heading from "../../components/heading";
import SelectedCourseInfo from "../../components/selectedCourseInfo";
import UsePageTitle from "../../components/usePageTitle";
import { iBreadcrumbItem } from "../../interfaces/breadcrumb";
import { iCourseDetails } from "../../interfaces/course";

/** Página que indica que el usuario debé estar identificado para poder registrarse en un curso */
export default function CourseIdentification() {
    const { id } = useParams<{ id: string }>();
    const [course, setCourse] = useState<iCourseDetails | null>(null);

    const { state } = useLocation() as {
        state: { course: iCourseDetails } | null;
    };

    useEffect(() => {
        if (state?.course) {
            setCourse(state?.course);
        } else {
            fetch(`/api/Course/Details/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    setCourse(data as iCourseDetails);
                });
        }
    }, [id, state]);

    const breadcrumbItems: iBreadcrumbItem[] = [
        { path: '/', text: 'Inicio' },
        { path: '/academia', text: 'Academia' },
        { path: `/clase/${id}`, text: `${course?.title}` },
        { path: `/clase/${id}/identificacion`, text: 'Registro' }
    ];

    UsePageTitle('Información de Registro');

    // TODO: no te regresa a donde estabas cuando le presionas en que ya tienes una cuenta

    return (
        <Content>
            <Heading text="Información de Registro" />
            <Breadcrumb items={breadcrumbItems} />
            <div className="uk-child-width-1-1 uk-child-width-1-3@s" data-uk-grid>
                <div>
                    <a href="/identificacion"
                        className="uk-display-block uk-text-center uk-tile uk-background-muted uk-box-shadow-hover-large">
                        <span>
                            <Icon path={mdiAccountCircle} size={5}
                                style={{ color: '#000' }} />
                        </span>
                        <h3 className="uk-margin-small">
                            Tengo una cuenta
                        </h3>
                        <p className="uk-margin-small">
                            Presiona para iniciar sesión
                        </p>
                    </a>
                </div>
                <div>
                    <Link to={`/clase/${id}/registro`} state={{ newUser: true }}
                        className="uk-display-block uk-text-center uk-tile uk-background-muted uk-box-shadow-hover-large">
                        <span>
                            <Icon path={mdiAccountPlus} size={5} style={{ color: '#000' }} />
                        </span>
                        <h3 className="uk-margin-small">
                            Soy nuevo
                        </h3>
                        <p className="uk-margin-small">
                            Continuar con el registro
                        </p>
                    </Link>
                </div>
                <div>
                    {course != null && <SelectedCourseInfo course={course} btnSelectAnotherCourse={true} />}
                </div>
            </div>
        </Content>
    );
}