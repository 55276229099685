import { mdiDelete, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UIkit from "uikit";
import Content from "../components/content";
import Heading from "../components/heading";
import UsePageTitle from "../components/usePageTitle";
import { iAddressOption } from "../interfaces/address";
import { iStatus } from "../interfaces/status";

/** Página que muestra la configuración */
export default function Configuration() {
    const [addresses, setAddresses] = useState<iAddressOption[]>([]);
    const [refreshAddresses, setrefreshAddresses] = useState<boolean>(false);

    useEffect(() => {
        // TODO: Revisar si se va a cambiar. Ya que se muestra el nombre que le dieron a la ubicación y no la ubicación como tal (calle, numero, colonia, etc.)
        fetch('/api/Address/GetAddresses')
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                setAddresses(data as iAddressOption[]);
            });
    }, [refreshAddresses]);

    const handleDelete = (id: string) => {
        UIkit.modal.confirm('<p>¿Estás seguro de que deseas eliminar la ubicación seleccionada?</p><p>Esta acción no se puede deshacer.<p>').then(function () {
            fetch(`api/Admin/DeleteAddress/${id}`)
                .then(res => {
                    if (!res.ok) {
                        window.location.href = `${window.location.origin}/Error`;
                    }

                    return res.json()
                })
                .then(data => {
                    const status = data as iStatus

                    status.messages?.forEach(mesagge => {
                        UIkit.notification({
                            message: `${mesagge}`,
                            status: status.success ? 'success' : 'danger',
                            pos: 'top-center'
                        });
                    });

                    setrefreshAddresses(!refreshAddresses);
                });
        }, function () {
            // nothing to do...
        })
    }

    UsePageTitle('Configuración');

    return (
        <Content>
            <Heading text="Configuración" />

            <section className="uk-margin-large-bottom">
                <div className="uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                    <div>
                        <h2>
                            Suscripciones
                        </h2>
                    </div>
                    <div>
                        <h2>
                            Ubicaciones
                        </h2>
                        {
                            addresses.map(m => (
                                <Fragment key={m.id}>
                                    <p className="uk-text-secondary uk-margin-remove-bottom">
                                        {m.name}
                                    </p>
                                    <div data-uk-margin>
                                        <Link
                                            to={`/configuracion/ubicaciones/${m.id}/editar`}
                                            className="uk-button uk-button-link uk-text-primary">
                                            <Icon path={mdiPencil} size={0.8} />
                                            <span className="uk-margin-small-left">
                                                Editar
                                            </span>
                                        </Link>
                                        <button
                                            onClick={() => handleDelete(m.id)}
                                            className="uk-button uk-button-link uk-text-primary">
                                            <Icon path={mdiDelete} size={0.8} />
                                            <span className="uk-margin-small-left">
                                                Eliminar
                                            </span>
                                        </button>
                                    </div>
                                </Fragment>
                            ))
                        }
                        <div className="uk-margin-medium-top">
                            <Link
                                to="/configuracion/ubicaciones/nueva"
                                className="uk-button uk-button-primary">
                                Agregar Ubicación
                            </Link>
                        </div>
                    </div>
                </div>
            </section >
        </Content>
    );
}