import React from "react";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";

/** Página para confirmar la compra de los productos */
export default function OrderPurshaceConfirmation() {
    UsePageTitle('Confirmación de compra');

    return (
        <Content>
            <Heading text="¡Gracias por tu compra!" />

            <div>
                <p>Contenido</p>
            </div>
        </Content>
    );
}