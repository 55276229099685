import React from "react";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";

/** Página para realizar el pago de los productos en el carrito */
export default function OrderPayment() {
    UsePageTitle('Información de Pago');

    return (
        <Content>
            <Heading text="Información de Pago" />

            <div>
                <p>Contenido</p>
            </div>
        </Content>
    );
}