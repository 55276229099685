import { mdiClose, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { InputHTMLAttributes, KeyboardEvent, useEffect, useRef, useState } from "react";

/**
 * Propiedades del componente DynamicList.
 * 
 * Extiende de InputHTMLAttributes<HTMLInputElement> para utilizar los atributos de un input normal como propiedades.
 */
export interface iProps extends InputHTMLAttributes<HTMLInputElement> {
    /** Función que se llama cada vez que la lista de elementos cambia. Recibe el name del input y un string con los elementos unidos por saltos de línea.*/
    onItemsChange: (name: string, value: string) => void;
}

/**
 * InputDynamicList Component
 * 
 * Un componente de lista dinámica que permite a los usuarios agregar y eliminar elementos de una lista.
 * Los elementos se pueden introducir en un campo de texto y, al presionar "Enter" o hacer clic en el botón,
 * se agregan a la lista. Los elementos existentes se muestran en una lista ordenada con la opción de eliminarlos.
 * 
 * Ejemplo de uso:
 *
 * <InputDynamicList 
 *     id="inputId"
       name="inputName"
 *     values={"Item 1\nItem2\nItem3"]} 
 *     onItemsChange={(updatedItems)} 
 * />
 *
 * 
 * Notas:
 * - Las propiedades de la función onItemsChange actuan como clave valor para saber que propiedad actualizar en un modelo.
 * - La entrada de texto se limpia automáticamente después de agregar un elemento y se enfoca para facilitar la entrada continua.
 */
export default function InputDynamicList({ name, value, onItemsChange, ...props }: iProps) {
    const [items, setItems] = useState<string[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!value) return

        setItems(value.toString().split('\n'));
    }, [value]);

    useEffect(() => {
        onItemsChange(name ?? '', items.join('\n'))
    }, [items]);

    const addItem = () => {
        if (!inputRef.current || !inputRef.current.value.trim()) return;

        const newValue = inputRef.current.value.trim();

        setItems((prevItems) => [
            ...prevItems,
            newValue
        ]);

        inputRef.current.value = '';
        inputRef.current.focus();
    }

    const removeItem = (index: number) => {
        setItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            addItem();
        }
    };

    return (
        <div className="input-dynamic-list">
            <div className="dynamic-list-panel">
                {
                    items.length > 0 ? (
                        <ul>
                            {items.map((item, index) => (
                                <li key={`item-${index}`}>
                                    <div>
                                        <div>
                                            {item}
                                        </div>
                                        <button type="button" onClick={() => removeItem(index)}>
                                            <Icon path={mdiClose} size={1} />
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                            <div className="uk-text-muted uk-padding-small" onClick={() => inputRef.current?.focus() }>
                                Escribe y presiona el botón <code>+</code> o la tecla <code>Enter</code> para agregar elementos en la lista
                        </div>
                    )
                }
            </div>
            <div className="dynamic-list-input">
                <input
                    {...props}
                    className={`uk-input ${props.className}`}
                    ref={inputRef}
                    onKeyDown={handleKeyPress} />

                <button type="button" className="uk-button uk-button-secondary uk-margin-xsmall-left" onClick={addItem} title="Agregar" data-uk-tooltip="">
                    <Icon path={mdiPlus} size={1} />
                </button>
            </div>
        </div>
    );
}
