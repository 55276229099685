import React, { Children } from "react";

interface iContainerProps {
    children?: React.ReactNode;
}

export default function Content(props: iContainerProps) {
    return (
        <div className="uk-container">
            <div className="uk-section">
                {props.children}
            </div>
        </div>
    );
}