import React from "react";
import { Link } from "react-router-dom";
import { iSubscriptionDetails } from "../interfaces/subscription";
import { formatConjunction } from "../utils";
import SubscriptionStatusLabel from "./subscriptionStatusLabel";

interface iProps {
    /** Modelo con los datos de la suscripción */
    subscription: iSubscriptionDetails;

    /** Indica si el componente se esta viendo desde la administración */
    isFromManagePage?: boolean;
}

export default function SubscriptionCardInfo({ subscription, isFromManagePage }: iProps) {
    const img = subscription.course?.media && subscription.course?.media.length > 0 ? `/storage/courses/${subscription.course?.media[0]}` : "/img/no-image.jpg";

    return (
        <article className="uk-grid-small uk-child-width-1-1 uk-child-width-1-3@s" data-uk-grid>
            <div className="uk-width-auto@s">
                <img height="100" width="100" src={img} alt="Imagen del curso" />
            </div>
            <div className="uk-width-expand@s">
                <div data-uk-margin>
                    <SubscriptionStatusLabel status={subscription.status} />
                    <span className="uk-h5 uk-margin-remove-vertical">
                        {subscription.course?.title} #{subscription.id}
                    </span>
                </div>
                <ul className="uk-list uk-margin-small">
                    <li>
                        {subscription.participants.length} Alumnos
                    </li>
                    <li>
                        <strong>Diciplinas: </strong>
                        {formatConjunction(subscription.course?.disciplines ?? [])}
                    </li>
                    <li>
                        <strong>Niveles: </strong>
                        {formatConjunction(subscription.course?.levels ?? [])}
                    </li>
                </ul>
            </div>
            <div className="uk-width-auto@s">
                <Link
                    to={`/suscripcion/${subscription.id}`}
                    state={{ subscription, isFromManagePage }}
                    className="uk-button uk-button-secondary uk-button-small">
                    Ver Detalles
                </Link>
            </div>
        </article>
    );
}