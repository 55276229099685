import React from "react";
import Content from "../components/content";
import Heading from "../components/heading";
import UsePageTitle from "../components/usePageTitle";

/** Página que muestra información sobre Lynx */
export default function AboutUs() {
    UsePageTitle('Acerca de nosotros');

    return (
        <Content>
            <Heading text="Acerca de nosotros" />

            <section>
                <p>Texto</p>
            </section>
        </Content>
    );
}