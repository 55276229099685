import React from "react";
import { NavLink } from "react-router-dom";
import { iBreadcrumbItem } from "../interfaces/breadcrumb";

/**
 * Lista de items a mostrar en el componente Breadcrumb
 */
export interface iBreadcrumbProps {
    items: iBreadcrumbItem[];
}

/**
 * Componente que muestra una navegación de las páginas anteriores a la actual
 */
export default function Breadcrumb({ items }: iBreadcrumbProps) {
    return (
        <nav aria-label="Breadcrumb">
            <ul className="uk-breadcrumb">
                {items.map((item, index) => (
                    <li key={index}>
                        <NavLink to={item.path}>
                            {item.text}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
}
