import { mdiFacebook, mdiInstagram } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link } from "react-router-dom";

/**  Footer de la página */
export default function Footer() {
    /** Obtiene el año actual */
    const currentDate = new Date().getFullYear();

    return (
        <footer className="uk-background-secondary uk-light uk-position-z-index" uk-sticky="position: bottom">
            <section className="uk-section-small uk-text-center">
                <p className="uk-margin-small">
                    {currentDate} Todos los derechos reservados. Lynx Skate House. <Link to="/terminos-y-condiciones">Términos y condiciones de uso</Link>. <Link to="/politica-de-privacidad">Aviso de Privacidad</Link>.
                </p>
                <p className="uk-margin-small">
                    Sitio Web Desarrollado por <a href="https://proyeccionweb.com" target="_blank">Proyección Web</a>
                </p>
                <div className="uk-margin-small">
                    <ol className="uk-iconnav uk-flex-center">
                        <li>
                            <a href="https://www.facebook.com/lynxskatehouse/?locale=es_LA" target="_blank" title="Síguenos en Facebook" data-uk-tooltip="">
                                <Icon path={mdiFacebook} size={1} />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/lynxskatehouse/?hl=en" target="_blank" title="Síguenos en Instagram" data-uk-tooltip="">
                                <Icon path={mdiInstagram} size={1} />
                            </a>
                        </li>
                    </ol>
                </div>
            </section>
        </footer>
    );
}