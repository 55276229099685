import "cleave.js/dist/addons/cleave-phone.mx.js";
import Cleave from "cleave.js/react";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UIkit from "uikit";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";
import { iShippingAddress } from "../../interfaces/shippingAddress";
import { iStatus } from "../../interfaces/status";

const initialShippingAddressData: iShippingAddress = {
    name: '',
    phone: '',
    street: '',
    streetNumber: '',
    neighborhood: '',
    zipCode: '',
    city: '',
    state: '',
    note: ''
}

/** Página para registrar una dirección de envío  */
export default function ShippingAddress() {
    const navigate = useNavigate();
    const [model, setModel] = useState<iShippingAddress>(initialShippingAddressData);
    const [hasShippingAddress, setHasShippingAddress] = useState<boolean>(false);
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

    useEffect(() => {
        fetch('/api/Me/ShippingAddress/FormData')
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                if (Object.keys(data).length > 0) {
                    setModel(data as iShippingAddress);
                    setHasShippingAddress(true);
                }
            });
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setModel({
            ...model,
            [name]: value
        });
    };

    /** Función que maneja el cambio del componente InputPhone */
    const handleInputPhone = (name: string, value: string) => {
        setModel(preModel => ({
            ...preModel,
            [name]: value
        }));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model)
        };

        setFormSubmitted(true);

        fetch('/api/Me/ShippingAddress/Register', requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                setErrors({});

                if (status.success) navigate("/");

                status.messages?.forEach(mesagge => {
                    UIkit.notification({
                        message: `${mesagge}`,
                        status: status.success ? 'success' : 'danger',
                        pos: 'top-center'
                    });
                });

                if (status.errors) setErrors(data.errors);
            })
            .finally(() => {
                setFormSubmitted(false);
            });
    }

    const handleDelete = () => {
        fetch('/api/Me/ShippingAddress/Delete')
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                if (status.success) navigate("/");

                status.messages?.forEach(mesagge => {
                    UIkit.notification({
                        message: `${mesagge}`,
                        status: status.success ? 'success' : 'danger',
                        pos: 'top-center'
                    });
                });
            });
    }

    const getButtonText = () => {
        if (hasShippingAddress) {
            return formSubmitted ? 'Actualizando...' : 'Actualizar';
        } else {
            return formSubmitted ? 'Guardando...' : 'Guardar';
        }
    };

    UsePageTitle('Información de envío');

    return (
        <Content>
            <Heading text="Información de envío" />

            <div>
                <p>
                    ¡Realiza compras rápidamente! Actualiza la información de envío que utilizaremos para autocompletar el formulario en tu siguiente compra.
                </p>
                <div className="uk-grid-large" data-uk-grid>
                    <div className="uk-width-1-1 uk-width-2-3@s">
                        <form action="/" method="post" onSubmit={handleSubmit} className="uk-form-horizontal uk-margin-large">
                            <fieldset className="uk-fieldset" disabled={formSubmitted}>
                                <div className="uk-margin-small">
                                    <label className="uk-form-label" htmlFor="name">Nombre completo:</label>
                                    <div className="uk-form-controls">
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            autoComplete="name"
                                            max="255"
                                            className="uk-input"
                                            value={model.name}
                                            onChange={handleChange}
                                            required />

                                        {errors?.Name && errors.Name.map((error, index) => (
                                            <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                        ))}
                                    </div>
                                </div>
                                <div className="uk-margin-small">
                                    <label className="uk-form-label" htmlFor="phone">Teléfono:</label>
                                    <div className="uk-form-controls">
                                        <Cleave
                                            id="phone"
                                            name="phone"
                                            value={model.phone}
                                            required
                                            autoComplete="tel"
                                            className="uk-input"
                                            options={{
                                                phone: true,
                                                phoneRegionCode: 'MX'
                                            }}
                                            onChange={(e: ChangeEvent) => handleInputPhone("phone", (e.target as any).rawValue)}
                                        />

                                        {errors?.Phone && errors.Phone.map((error, index) => (
                                            <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                        ))}
                                    </div>
                                </div>
                                <div className="uk-margin-small">
                                    <label className="uk-form-label" htmlFor="address">Dirección:</label>
                                    <div className="uk-form-controls">
                                        map component
                                    </div>
                                </div>
                                <div className="uk-margin-small">
                                    <label className="uk-form-label" htmlFor="street">Calle y número:</label>
                                    <div className="uk-form-controls">
                                        <div className="uk-grid-small" data-uk-grid>
                                            <div className="uk-width-1-1 uk-width-1-2@s">
                                                <input
                                                    type="text"
                                                    id="street"
                                                    name="street"
                                                    autoComplete="shipping address-level1"
                                                    max="255"
                                                    className="uk-input"
                                                    value={model.street}
                                                    onChange={handleChange}
                                                    required />

                                                {errors?.Street && errors.Street.map((error, index) => (
                                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                                ))}
                                            </div>
                                            <div className="uk-width-1-1 uk-width-1-2@s">
                                                <input
                                                    type="text"
                                                    id="streetNumber"
                                                    name="streetNumber"
                                                    autoComplete="shipping address-level2"
                                                    max="20"
                                                    className="uk-input"
                                                    value={model.streetNumber}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Número Interior" />

                                                {errors?.StreetNumber && errors.StreetNumber.map((error, index) => (
                                                    <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-margin-small">
                                    <label className="uk-form-label" htmlFor="neighborhood">Colonia:</label>
                                    <div className="uk-form-controls">
                                        <input
                                            type="text"
                                            id="neighborhood"
                                            name="neighborhood"
                                            autoComplete="shipping address-level3"
                                            max="255"
                                            className="uk-input"
                                            value={model.neighborhood}
                                            onChange={handleChange}
                                            required />

                                        {errors?.Neighborhood && errors.Neighborhood.map((error, index) => (
                                            <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                        ))}
                                    </div>
                                </div>
                                <div className="uk-margin-small">
                                    <label className="uk-form-label" htmlFor="zipCode">Código Postal:</label>
                                    <div className="uk-form-controls">
                                        <Cleave
                                            id="zipCode"
                                            name="zipCode"
                                            value={model.zipCode}
                                            required
                                            autoComplete="shipping postal-code"
                                            className="uk-input"
                                            options={{
                                                blocks: [5],
                                                numericOnly: true,
                                                numeralPositiveOnly: true
                                            }}
                                            onChange={(e: ChangeEvent) => setModel(prevModel => ({ ...prevModel, cvv: (e.target as any).rawValue }))}
                                        />

                                        {errors?.ZipCode && errors.ZipCode.map((error, index) => (
                                            <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                        ))}
                                    </div>
                                </div>
                                <div className="uk-margin-small">
                                    <label className="uk-form-label" htmlFor="city">Ciudad:</label>
                                    <div className="uk-form-controls">
                                        <input
                                            type="text"
                                            id="city"
                                            name="city"
                                            autoComplete="shipping address-level2"
                                            max="255"
                                            className="uk-input"
                                            value={model.city}
                                            onChange={handleChange}
                                            required />

                                        {errors?.City && errors.City.map((error, index) => (
                                            <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                        ))}
                                    </div>
                                </div>
                                <div className="uk-margin-small">
                                    <label className="uk-form-label" htmlFor="state">Estado:</label>
                                    <div className="uk-form-controls">
                                        <input
                                            type="text"
                                            id="state"
                                            name="state"
                                            autoComplete="shipping address-level1"
                                            max="255"
                                            className="uk-input"
                                            value={model.state}
                                            onChange={handleChange}
                                            required />

                                        {errors?.State && errors.State.map((error, index) => (
                                            <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                        ))}
                                    </div>
                                </div>
                                <div className="uk-margin-small">
                                    <label className="uk-form-label" htmlFor="note">Notas:</label>
                                    <div className="uk-form-controls">
                                        <input
                                            type="text"
                                            id="note"
                                            name="note"
                                            autoComplete="off"
                                            max="65535"
                                            className="uk-input"
                                            value={model.note}
                                            onChange={handleChange}
                                            placeholder="Escribe aquí las instrucciones para la entrega" />

                                        {errors?.Note && errors.Note.map((error, index) => (
                                            <div key={index} className="uk-text-small uk-text-danger">{error}</div>
                                        ))}
                                    </div>
                                </div>
                            </fieldset>
                            <button type="submit" className="uk-button uk-button-primary" disabled={formSubmitted}>
                                {getButtonText()}
                            </button>
                        </form>
                    </div>
                </div>
                {hasShippingAddress && <p>
                    o <button onClick={handleDelete} className="uk-button uk-button-link uk-text-primary">eliminar información de envío</button>
                </p>}
            </div>
        </Content>
    );
}