import React from "react";
import Breadcrumb from "../../components/breadcrumb";
import Content from "../../components/content";
import Heading from "../../components/heading";
import ProductIndex from "../../components/productIndex";
import UsePageTitle from "../../components/usePageTitle";
import { iBreadcrumbItem } from "../../interfaces/breadcrumb";

const breadcrumbItems: iBreadcrumbItem[] = [
    { path: '/', text: 'Inicio' },
    { path: '/productos', text: 'Productos' }
];

/** Página principal de los productos */
export default function Products() {
    UsePageTitle('Productos');

    return (
        <Content>
            <Heading text="Productos" />

            <div>
                <Breadcrumb items={breadcrumbItems} />
            </div>

            <ProductIndex />
        </Content>
    );
}