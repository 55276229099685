import React from "react";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";

/** Página para renovar una suscripción */
export default function SubscriptionRenewal() {
    UsePageTitle('Información de Pago');

    return (
        <Content>
            <Heading text="Información de Pago" />
            <section>
                <p>Contenido</p>
            </section>
        </Content>
    );
}