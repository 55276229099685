import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { iPagination } from "../interfaces/pagination";
import { iProductIndex } from "../interfaces/product";
import { getPaginationRangeText } from "../utils";
import Pagination from "./pagination";
import ProductCardInfo from "./productCardInfo";

/**
 * Muestra los datos necesarios para el index de los productos.
 * 
 * @param isFromManagePage indica si el componente se esta llamando desde la administración de productos.
 * @returns
 */
export default function ProductIndex({ isFromManagePage = false }: { isFromManagePage?: boolean }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState<iProductIndex | null>(null);
    const [query, setQuery] = useState<string>('');
    const [pagination, setPagination] = useState<iPagination | null>(null);

    useEffect(() => {
        const paginationParams = new URLSearchParams(Object.entries(pagination ?? {}))

        const paginationQueryString = paginationParams.toString();

        let params = '';

        if (paginationQueryString) {
            if (params) {
                params += `&${paginationQueryString}`;
            } else {
                params += paginationQueryString;
            }
        }

        if (location.search != params) {
            setQuery(params);
            navigate({ search: params });
        }
    }, [pagination]);

    useEffect(() => {
        fetch(`/api/Product/Index?${query}`)
            .then(res => res.json())
            .then(data => {
                setData(data as iProductIndex);
            });
    }, [query]);

    useEffect(() => {
        const search = location.search.slice(1);

        if (search != query) {
            setQuery(search);
        }
    }, [location.search])

    const handlePageChange = (page: number) => {
        setPagination({
            ...pagination,
            currentPage: page
        });
    };

    return (
        <>
            <div className="uk-margin">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                    <div>
                        {getPaginationRangeText(data?.pagination?.currentPage, data?.pagination?.itemsPerPage, data?.totalItems)}
                    </div>
                </div>
            </div>

            <div className="uk-margin">
                <div className="uk-child-width-1-2@s" data-uk-grid>
                    {data && data.list && data.list?.length > 0 ? (
                        data.list.map(product => (
                            <div key={product.id}>
                                <ProductCardInfo product={product} isFromManagePage={isFromManagePage} />
                            </div>
                        ))
                    ) : (
                        <p>No hay productos disponibles.</p>
                    )}
                </div>
            </div>

            {data && data.totalItems && data.totalPages && data.totalItems > 0 && data.totalPages > 1 && <section className="uk-section uk-section-small">
                <Pagination
                    currentPage={data.pagination.currentPage ?? 1}
                    totalPages={data.totalPages}
                    onPageChange={handlePageChange}
                />
            </section>}
        </>
    );
}