import { ADMIN, CLASSCAPTURIST, Constants, GENERALUSER, INSTRUCTOR, PRODUCTCAPTURIST } from "./constants";

export const SubscriptionStatusDictionary: { [key: string]: string } = {
    [Constants.SubscriptionStatus.Active]: "Activa",
    [Constants.SubscriptionStatus.Finished]: "Finalizada",
    [Constants.SubscriptionStatus.PendingPayment]: "Pendiente de pago",
    [Constants.SubscriptionStatus.Canceled]: "Cancelada"
};

export const UserRoleDictionary: { [key: string]: string } = {
    [GENERALUSER]: "Usuario General",
    [INSTRUCTOR]: "Instructor",
    [PRODUCTCAPTURIST]: "Capturista de Productos",
    [CLASSCAPTURIST]: "Capturista de Cursos",
    [ADMIN]: "Administrador"
};
