import React, { useEffect, useState } from "react";
import Content from "../../components/content";
import Heading from "../../components/heading";
import SubscriptionCardInfo from "../../components/subscriptionCardInfo";
import UsePageTitle from "../../components/usePageTitle";
import { iSubscriptionIndex } from "../../interfaces/subscription";
import { useLocation, useNavigate } from "react-router";
import { iPagination } from "../../interfaces/pagination";
import Pagination from "../../components/pagination";

/** Página principal de las clases del usuario */
export default function Subscription() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState<iSubscriptionIndex | null>(null);
    const [pagination, setPagination] = useState<iPagination | null>(null);
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        const paginationParams = new URLSearchParams(Object.entries(pagination ?? {}))

        const paginationQueryString = paginationParams.toString();

        let params = '';

        if (paginationQueryString) {
            if (params) {
                params += `&${paginationQueryString}`;
            } else {
                params += paginationQueryString;
            }
        }

        if (location.search != params) {
            setQuery(params);
            navigate({ search: params });
        }
    }, [pagination]);

    useEffect(() => {
        fetch(`/api/Me/Subscription/Index?${query}`)
            .then(res => res.json())
            .then(data => {
                setData(data as iSubscriptionIndex);
            });
    }, [query]);

    useEffect(() => {
        const search = location.search.slice(1);

        if (search != query) {
            setQuery(search);
        }
    }, [location.search])

    const handlePageChange = (page: number) => {
        setPagination({
            ...pagination,
            currentPage: page
        });
    };

    UsePageTitle('Mis Suscripciones');

    return (
        <Content>
            <Heading text="Mis Suscripciones" />

            <div className="uk-grid-match uk-child-width-1-1 uk-child-width-1-2@s" data-uk-grid>
                {data && data.list && data.list.length > 0 ? (
                    data.list.map(subscription => (
                        <div key={subscription.id} >
                            <SubscriptionCardInfo subscription={subscription} isFromManagePage={false} />
                        </div>
                    ))
                ) : (
                    <p>No hay suscripciones disponibles.</p>
                )}
            </div>

            {data && data.totalItems && data.totalPages && data.totalItems > 0 && data.totalPages > 1 && <section className="uk-section uk-section-small">
                <Pagination
                    currentPage={data.pagination.currentPage ?? 1}
                    totalPages={data.totalPages}
                    onPageChange={handlePageChange}
                />
            </section>}
        </Content>
    );
}