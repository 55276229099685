import { mdiClose, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import UIkit from "uikit";
import { iSpecification } from "../interfaces/specification";

export interface iProps {
    /** Valor que tendrá por defecto el componente */
    defaultValue: iSpecification[];

    /** Función que se llama cada vez que la lista de elementos cambia. */
    onItemsChange: (value: iSpecification[]) => void;
}

/**
 * InputSpecification Component
 * 
 * Un componente de lista dinámica que permite a los usuarios agregar y eliminar elementos de una lista.
 * Los elementos se introducen en los campos correspondientes y, al presionar "Enter" o hacer clic en el botón,
 * se agregan a la lista. Los elementos existentes se muestran en una lista ordenada con la opción de eliminarlos.
*/
export default function InputSpecification({ defaultValue, onItemsChange }: iProps) {
    const [items, setItems] = useState<iSpecification[]>(defaultValue);

    const [activeSection, setActiveSection] = useState<number>(0);
    const [newSectionName, setNewSectionName] = useState<string>("");
    const [creatingNewSection, setCreatingNewSection] = useState<boolean>(false);

    const keyRef = useRef<HTMLInputElement>(null);
    const valueRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (defaultValue.length <= 0) return;

        setItems(defaultValue)
    }, [defaultValue]);

    useEffect(() => {
        onItemsChange(items);
    }, [items]);

    const addDetail = () => {
        if (!keyRef.current || !valueRef.current || items.length <= 0) return;

        const key = keyRef.current.value.trim();
        const value = valueRef.current.value.trim();

        if (!key || !value) return;

        const updatedItems = [...items];

        updatedItems[activeSection].specificationDetails.push({
            name: key,
            value: value,
        });

        setItems(updatedItems);

        keyRef.current.value = '';
        valueRef.current.value = '';

        keyRef.current.focus();
    };

    const addSection = () => {
        if (!newSectionName.trim()) return;

        const sectionName = newSectionName.trim();

        if (items.some(m => m.name == sectionName)) {
            UIkit.notification({
                message: 'No puedes repetir el nombre de una sección',
                status: 'danger',
                pos: 'top-center'
            });

            return;
        }

        const specification: iSpecification = {
            name: sectionName,
            specificationDetails: []
        }

        setItems([
            ...items,
            specification,
        ]);

        setNewSectionName("");
        setCreatingNewSection(false);
        setActiveSection(items.length);

        keyRef?.current?.focus();
    };

    const removeDetail = (sectionIndex: number, detailIndex: number) => {
        const updatedItems = [...items];
        updatedItems[sectionIndex].specificationDetails.splice(detailIndex, 1);
        setItems(updatedItems);
    };

    const removeSection = (sectionIndex: number) => {
        setItems((prevItems) => prevItems.filter((_, i) => i !== sectionIndex));

        // Si se elimina la sección seleccionada, se selecciona la primera por default.
        if (activeSection === sectionIndex) {
            setActiveSection(0);
        }
    };

    const handleDetailKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            addDetail();
        }
    };

    const handleSectionKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            addSection();
        }
    };

    return (
        <div>
            {/* Secciones */}
            <div className="uk-flex uk-margin-bottom uk-margin-medium-top" data-uk-margin>
                {items.map((section, index) => (
                    <div
                        key={`section-${index}`}
                        className="uk-button-group">

                        <button
                            type="button"
                            title="Ver sección"
                            data-uk-tooltip=""
                            className={`uk-button ${activeSection === index ? "uk-button-primary" : "uk-button-default"}`}
                            onClick={() => setActiveSection(index)}>

                            {section.name}
                        </button>

                        <button
                            type="button"
                            title="Eliminar sección"
                            data-uk-tooltip=""
                            className="uk-button uk-button-default"
                            onClick={() => removeSection(index)}>

                            <Icon path={mdiClose} size={0.8} />
                        </button>
                    </div>
                ))}

                {/* Input para agregar una nueva sección */}
                {!creatingNewSection ? (
                    <button
                        type="button"
                        title="Agregar sección"
                        data-uk-tooltip=""
                        className="uk-button uk-button-secondary"
                        onClick={() => setCreatingNewSection(true)}>
                        <Icon path={mdiPlus} size={1} />
                    </button>
                ) : (
                    <div className="uk-flex">
                        <input
                            className="uk-input"
                            value={newSectionName}
                            onChange={(e) => setNewSectionName(e.target.value)}
                            onKeyDown={handleSectionKeyPress}
                            autoFocus={true}
                            placeholder="Nueva sección" />

                        <button
                            type="button"
                            className="uk-button uk-button-secondary"
                            onClick={addSection}>
                            Agregar
                        </button>
                    </div>
                )}
            </div>

            {/* Detalles de la sección activa */}
            <div className="input-dynamic-list">
                <div className="dynamic-list-panel">
                    {
                        items[activeSection]?.specificationDetails.length > 0 ? (
                            <ul>
                                {items[activeSection].specificationDetails.map((detail, detailIndex) => (
                                    <li key={`detail-${detailIndex}`}>
                                        <div>
                                            <div className="uk-grid-small" data-uk-grid>
                                                <div className="uk-width-expand">
                                                    {detail.name}:
                                                </div>

                                                <div className="uk-width-expand">
                                                    <strong>{detail.value}</strong>
                                                </div>
                                            </div>

                                            <button type="button" onClick={() => removeDetail(activeSection, detailIndex)} >
                                                <Icon path={mdiClose} size={1} />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="uk-text-muted uk-padding-small" onClick={() => keyRef.current?.focus()}>
                                Crea una sección, rellena los campos y presiona el botón <code>+</code> o la tecla <code>Enter</code> para agregar elementos en la lista
                            </div>
                        )
                    }
                </div>

                {/* Inputs para agregar detalles */}
                <div className="dynamic-list-input">
                    <div className="uk-grid-small" data-uk-grid>
                        <div className="uk-width-expand">
                            <input
                                type="text"
                                className="uk-input uk-margin-right"
                                placeholder="Nombre"
                                ref={keyRef}
                                onKeyDown={handleDetailKeyPress} />
                        </div>

                        <div className="uk-width-expand">
                            <input
                                type="text"
                                className="uk-input uk-margin-right"
                                placeholder="Valor"
                                ref={valueRef}
                                onKeyDown={handleDetailKeyPress} />
                        </div>

                        <div className="uk-width-auto uk-margin-xsmall-left">
                            <button
                                type="button"
                                title="Agregar"
                                data-uk-tooltip=""
                                className="uk-button uk-button-secondary"
                                onClick={addDetail}>
                                <Icon path={mdiPlus} size={1} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
