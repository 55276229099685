import { useEffect } from 'react';

/**
 * Componente que se encarga de actualizar el título de la página.
 * 
 * @param title - Título que llevará la página
 */
export default function UsePageTitle(title: string) {
    useEffect(() => {
        document.title = title;
    }, [title]);
};
