import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import UIkit from "uikit";
import CourseForm from "../../components/courseForm";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";
//import { iAddressBase } from "../../interfaces/address";
import Content from "../../components/content";
import { iCourseForm } from "../../interfaces/course";
import { iStatus } from "../../interfaces/status";

/** Página para crear un curso */
export default function CourseCreate() {
    const navigate = useNavigate();
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

    const handleSubmit = useCallback((courseData: iCourseForm/*, addressData: iAddressBase*/) => {
        //const model = {
        //    ...courseData,
        //    ...addressData
        //}

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(courseData)
        };

        setFormSubmitted(true);

        fetch('/api/ClassCapturist/createCourse', requestOptions)
            .then(res => {
                if (!res.ok) {
                    window.location.href = `${window.location.origin}/Error`;
                }

                return res.json()
            })
            .then(data => {
                const status = data as iStatus

                setErrors({});

                if (status.success) navigate(-1);

                status.messages?.forEach(mesagge => {
                    UIkit.notification({
                        message: `${mesagge}`,
                        status: status.success ? 'success' : 'danger',
                        pos: 'top-center'
                    });
                });

                if (status.errors) setErrors(data.errors);
            })
            .finally(() => {
                setFormSubmitted(false);
            });
    }, [])

    UsePageTitle('Nuevo curso');

    return (
        <>
            <Content>
                <Heading text="Nuevo curso" closeOption={-1} />
                <CourseForm errors={errors}
                    onSubmit={handleSubmit}
                    formSubmitted={formSubmitted} />
            </Content>
        </>
    );
}