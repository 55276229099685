import { ActualFileObject, FilePondErrorDescription, FilePondFile, FilePondInitialFile, FileStatus, ProgressServerConfigFunction } from "filepond";
import * as FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import * as FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import * as FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import * as FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import * as FilePondPluginImageTransform from "filepond-plugin-image-transform";
import * as FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import "filepond/dist/filepond.min.css";
import React, { useRef } from "react";
import { FilePond, registerPlugin } from "react-filepond";

declare var filepond_es: any;

registerPlugin(
    FilePondPluginImagePreview,
    FilePondPluginImageExifOrientation,
    FilePondPluginFileValidateSize,
    FilePondPluginImageTransform,
    FilePondPluginFileValidateType,
    FilePondPluginImageValidateSize
);

export enum FileStates {
    Ready = "",
    Working = "working",
    Finished = "finished"
}

/** Propiedades del componente */
export interface iFileProps {
    /** Valor del componente */
    value?: Array<string>;
    /**
     * Evento que se ejecuta cuando cambian los archivos del componente
     * @param files archivos que del componente
     * @returns
     */
    onChange?: (files: Array<string>) => void;
    /**
     * Evento que se ejecuta cuando cambia el estado de subida de los archivos
     * @param state estado
     * @returns
     */
    onChangeState?: (state: FileStates) => void;
}

interface iFileItem {
    file?: FilePondFile;
    error?: FilePondErrorDescription | null;
    status?: any;
    progress?: number;
}

export default function FileUpload(props: iFileProps) {
    const pond = useRef<FilePond>(null);
    const value = useRef<Array<string>>();
    const state = useRef<FileStates>();

    const server = {
        url: "",
        process: (fieldName: string, file: ActualFileObject, metadata: { [key: string]: any }, load: (p: string | { [key: string]: any }) => void, error: (errorText: string) => void, progress: ProgressServerConfigFunction, abort: () => void) => {
            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();
            formData.append(fieldName, file, file.name);

            const request = new XMLHttpRequest();
            request.open('POST', '/api/uploads');

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    var response = JSON.parse(request.responseText);
                    load(response.filename);
                } else {
                    // Can call the error method if something is wrong, should exit after
                    error('oh no');
                }
            };

            request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort();

                    // Let FilePond know the request has been cancelled
                    abort();
                },
            };
        },
        load: '/temp/',
        fetch: '/api/uploads?url=',
        revert: null,
        restore: null
    };

    // Finished processing a file, if the detail object contains an error property, something went wrong
    const OnProcessFile = (error: FilePondErrorDescription | null, file: FilePondFile) => {
        handleChanges();
    };

    // A file has been added or removed
    const OnUpdateFiles = (newFiles: Array<FilePondFile>) => {
        handleChanges();
    };

    // Fired when the files list has been reordered, receives current list of files (reordered) plus file origin and target index.
    const OnReorderFiles = (newFiles: Array<FilePondFile>) => {
        handleChanges();
    };

    const handleChanges = () => {
        let newState: FileStates = FileStates.Ready;

        const files = pond.current?.getFiles();

        if (files && files.length > 0) {
            const processingFiles = files.filter(m => m.status != FileStatus.IDLE && m.status != FileStatus.PROCESSING_COMPLETE && m.status != FileStatus.LOAD_ERROR);

            if ((processingFiles?.length || 0) > 0) {
                newState = FileStates.Working;
            } else {
                newState = FileStates.Finished;
            }
        } else {
            newState = FileStates.Ready;
        }

        if (newState != state.current) {
            state.current = newState;

            if (typeof props.onChangeState == "function") {
                props.onChangeState(state.current);
            }
        }

        if (typeof props.onChange == "function" && newState == FileStates.Finished) {
            const newValue: Array<string> = files?.map(m => m.serverId?.split("/").pop() || "").filter(m => m.length > 0) || [];

            if (newValue.join(",") != props.value?.join(",")) {
                value.current = newValue;
                props.onChange(newValue);
            }
        }
    }

    const uploadedFiles = props.value?.map((fileName, index): FilePondInitialFile => {
        return {
            source: fileName,
            options: {
                type: 'local'
            },
        }
    });

    return (
        <FilePond
            ref={pond}
            server={server}
            acceptedFileTypes={['image/png', 'image/jpeg', 'image/webp', 'image/bmp']}
            maxFileSize={"10MB"}
            allowMultiple
            allowReorder
            maxFiles={50}
            styleItemPanelAspectRatio={"1"}
            imageValidateSizeMinResolution={500 * 500}
            imageValidateSizeMaxResolution={6000 * 6000}
            styleButtonRemoveItemPosition={"right"}
            onprocessfile={OnProcessFile}
            onupdatefiles={OnUpdateFiles}
            onreorderfiles={OnReorderFiles}
            files={uploadedFiles}
        />
    );
}