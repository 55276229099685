import React from "react";
import Content from "../../components/content";
import Heading from "../../components/heading";
import UsePageTitle from "../../components/usePageTitle";

/**  Página para editar una suscripción */
export default function SubscriptionEdit() {
    UsePageTitle('Editar suscripción');

    return (
        <Content>
            <Heading text="Editar suscripción" />

            <section>
                <p>Contenido</p>
            </section>
        </Content>
    );
}